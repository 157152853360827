import React from "react";
const SportsTab = ({ setTab, tab, type }) => {
  return (
    <div className="w-100 py-1 px-0 d-flex justify-content-center align-items-center">
      <div className="sport-header-inner-new">
        <div>
          <h3
            onClick={() => setTab("exchange")}
            className={tab == "exchange" ? "active" : ""}
          >
            Exchange
          </h3>
        </div>
        <div>
          <h3
            onClick={() => setTab("bookmaker")}
            className={tab == "bookmaker" ? "active" : ""}
          >
            Bookmaker
          </h3>
        </div>
        <div>
          <h3
            onClick={() => setTab("fancy")}
            className={tab == "fancy" ? "active" : ""}
          >
            Fancybet
          </h3>
        </div>

        {type !== "casino" && (
          <div>
            <h3
              onClick={() => setTab("casino")}
              className={tab == "casino" ? "active" : ""}
            >
              Casino
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default SportsTab;
