import { render, screen } from "@testing-library/react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Carousel from "react-bootstrap/Carousel";

import Header from "../component/header";
import Container from "react-bootstrap/Container";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import home from "../img/home.png";
import Cricket from "../img/cricket.png";
import Tennis from "../img/tennis.png";
import Basketball from "../img/basketball.png";
import Soccer from "../img/soccer.png";
import Golf from "../img/golf.png";
import Politics from "../img/poltcs.png";
import Baseball from "../img/baseball.png";
import lbanner from "../img/left-banner.png";
import IndFlag from "../img/flag-india.png";
import PakFlag from "../img/flag-Pak.png";
import VS from "../img/vs.png";
import UpcomingDark from "../img/upcoming-dark.png";
import live from "../img/live.png";
import BatBlue from "../img/bat-blue.png";
import trophy from "../img/trophy.png";
import telecase from "../img/telecast.png";
import MBanner from "../img/middle-banner.png";
import upcoming from "../img/upcoming.png";
import InPlayDark from "../img/inplay-dark.png";
import Racket from "../img/racket.png";
import RightBanner from "../img/right-banner.png";
import RightBanner1 from "../img/right-banner-1.png";
import Dice from "../img/dise.png";
import Whatsapp from "../assets/images/whatapp.svg";
import VSWhite from "../img/vs-white.png";
import BetSlipContext from "../context/BetSlipContext";
import { useState, useEffect, useContext } from "react";
import { isEmpty } from "lodash";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import ListPage from "./Home/ListPage";
import Home from "./home";
import PlaceBet from "../component/PlaceBet";
import OpenBets from "../component/OpenBets";
import AuthContext from "../context/AuthContext";
import loading from "../img/loading_bet.webp";
import CalendarComponent from "../component/Calendar";
function Layout({ children }) {
  const params = useParams();
  const location = useLocation();
  const [tournamentData, setTournamentData] = useState([]);
  const navigate = useNavigate();
  const { rightTab, setRightTab, showDate, totalCount, user } =
    useContext(AuthContext);
  const { betSlipObject, betLoader } = useContext(BetSlipContext);
  const sortBy = (array) => {
    return array?.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  };

  const getTournamentData = async () => {
    const { status, data: response_users } = await apiGet(apiPath.seriesList);
    if (status === 200) {
      if (response_users.success) {
        setTournamentData({
          cricket:
            response_users.results?.filter((res) => {
              return res?.gameType == "cricket";
            }) || [],
          tennis:
            response_users.results?.filter((res) => {
              return res?.gameType == "tennis";
            }) || [],
          soccer:
            response_users.results?.filter((res) => {
              return res?.gameType == "soccer";
            }) || [],
          all: sortBy(response_users.results),
        });
      }
    }
  };

  useEffect(() => {
    getTournamentData();
  }, []);

  return (
    <div style={betLoader ? { cursor: "not-allowed" } : {}}>
      {" "}
      {!["match", "customer-support"].includes(
        location?.pathname?.split("/")[1]
      ) && (
        <div>
          <img
            src={Whatsapp}
            onClick={
              () => {
                if (isEmpty(user)) {
                  navigate("/login");
                } else {
                  navigate("/customer-support");
                }
              }
              // window.open(
              //   "https://api.whatsapp.com/send?phone=917301476666&text="
              // )
            }
            style={{
              position: "fixed",
              bottom: "5%",
              left: "5%",
              zIndex: "99999999999999999",
            }}
          />
          {totalCount > 0 && (
            <span
              style={{
                position: "fixed",
                bottom: "10.5%",
                left: "7.5%",
                zIndex: "99999999999999999",
                background: "red",
                color: "white",
                borderRadius: "50%",
                padding: "0 4px",
              }}
            >
              {totalCount}
            </span>
          )}
        </div>
      )}
      {betLoader && (
        <div className="loading-bet">
          <img src={loading} />
        </div>
      )}
      <div>
        <Container fluid p-0>
          <Header />
          <div className="d-flex">
            <div className="left-menu">
              <h2
                data-aos="zoom-in-up"
                data-aos-easing="ease"
                data-aos-delay="400"
              >
                Casino
              </h2>
              <ul
                data-aos="zoom-in-up"
                data-aos-easing="ease"
                data-aos-delay="400"
                className="blocks"
              >
                {casinoList?.length > 0 &&
                  casinoList?.map((res) => {
                    return (
                      <li>
                        <Link
                          className={
                            location?.pathname?.includes("casino") &&
                            location?.pathname?.includes(res?.key) &&
                            "active-side"
                          }
                          to={`/casino/${res?.key}`}
                        >
                          {res?.title}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
              <div
                data-aos="zoom-in-up"
                data-aos-easing="ease"
                data-aos-delay="400"
                className="LeftTabs"
              >
                <Tabs
                  fill
                  defaultActiveKey="betslip"
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey="betslip" title="Live">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Cricket</Accordion.Header>
                        {tournamentData?.cricket?.length > 0 && (
                          <Accordion.Body>
                            <ul>
                              {tournamentData?.cricket?.map((res) => {
                                return (
                                  <li>
                                    <Link to={`/${res?.gameType}`}>
                                      {res?.seriesName}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        )}
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Soccer</Accordion.Header>
                        {tournamentData?.soccer?.length > 0 && (
                          <Accordion.Body>
                            <ul>
                              {tournamentData?.soccer?.map((res) => {
                                return (
                                  <li>
                                    <Link to={`/${res?.gameType}`}>
                                      {res?.seriesName}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        )}
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Tennis</Accordion.Header>
                        {tournamentData?.tennis?.length > 0 && (
                          <Accordion.Body>
                            <ul>
                              {tournamentData?.tennis?.map((res) => {
                                return (
                                  <li>
                                    <Link to={`/${res?.gameType}`}>
                                      {res?.seriesName}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        )}
                      </Accordion.Item>
                    </Accordion>
                  </Tab>
                  {/* <Tab eventKey="openBet" title="Pre Match">
                    dsfsdf
                  </Tab> */}
                </Tabs>
              </div>
            </div>
            <div className="right-con p-3 pb-0">
              <Row>
                {children}
             
                  <Col md={3}>
                    <div
                      data-aos="fade-up-left"
                      className="lb-box br-6 p-3 mb-3"
                    >
                      <Tabs
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        activeKey={rightTab}
                        onSelect={(e) => setRightTab(e)}
                      >
                        <Tab eventKey="betslip" title="Bet Slip">
                          {!isEmpty(betSlipObject?.teamName) &&
                          !isEmpty(betSlipObject?.betType) ? (
                            <PlaceBet />
                          ) : (
                            <div className="d-flex justify-content-center align-items-center">
                              No BetSlip
                            </div>
                          )}
                        </Tab>
                        <Tab eventKey="openBet" title="Open Bet">
                          <OpenBets />
                        </Tab>
                      </Tabs>
                    </div>
                    <p
                      data-aos="zoom-in-up"
                      data-aos-easing="ease"
                      data-aos-delay="400"
                    >
                      <img src={RightBanner} style={{ maxWidth: "100%" }} />
                    </p>
                    <p
                      data-aos="zoom-in-up"
                      data-aos-easing="ease"
                      data-aos-delay="400"
                    >
                      <img src={RightBanner1} style={{ maxWidth: "100%" }} />
                    </p>
                  </Col>
             
              </Row>
              <div className="btmBox mb-0 text-center fs-12 p-3">
                &copy; Copyright 2024 All Right Reserved By Betmates
              </div>
            </div>
          </div>
        </Container>
      </div>
      {showDate?.status && !isEmpty(showDate?.type) && <CalendarComponent />}
    </div>
  );
}

export default Layout;

const casinoList = [
  {
    title: "Indian Poker",
    key: "poker",
  },
  {
    title: "Aviator",
    key: "Aviator",
  },
  {
    title: "Live",
    key: "Live",
  },
  {
    title: "Table",
    key: "Table",
  },
  {
    title: "Slot",
    key: "Slot",
  },
  {
    title: "Dice",
    key: "Dice",
  },
  {
    title: "Fishing",
    key: "Fishing",
  },
  {
    title: "Egame",
    key: "Egame",
  },
  {
    title: "AWC",
    key: "AWC",
  },
];
