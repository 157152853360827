import { render, screen } from "@testing-library/react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Carousel from "react-bootstrap/Carousel";

import Header from "../component/header";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import home from "../img/home.png";
import Cricket from "../img/cricket.png";
import Tennis from "../img/tennis.png";
import Basketball from "../img/basketball.png";
import Soccer from "../img/soccer.png";
import Golf from "../img/golf.png";
import Politics from "../img/poltcs.png";
import Baseball from "../img/baseball.png";
import lbanner from "../img/left-banner.png";
import IndFlag from "../img/flag-india.png";
import PakFlag from "../img/flag-Pak.png";
import VS from "../img/vs.png";
import UpcomingDark from "../img/upcoming-dark.png";
import live from "../img/live.png";
import BatBlue from "../img/bat-blue.png";
import trophy from "../img/trophy.png";
import telecase from "../img/telecast.png";
import MBanner from "../img/middle-banner.png";
import upcoming from "../img/upcoming.png";
import InPlayDark from "../img/inplay-dark.png";
import Racket from "../img/racket.png";
import RightBanner from "../img/right-banner.png";
import RightBanner1 from "../img/right-banner-1.png";
import Dice from "../img/dise.png";
import VSWhite from "../img/vs-white.png";
import BetSlipContext from "../context/BetSlipContext";
import { useState, useEffect, useContext } from "react";
import { isEmpty } from "lodash";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import ListPage from "./Home/ListPage";
import Layout from "./Layout";
import { useParams } from "react-router-dom";
const Home = () => {
  const { sportType } = useParams();
  return (
    <Layout>
      {/* {isEmpty(sportType) && (
          <>
            <div
              data-aos="zoom-in-up"
              data-aos-easing="ease"
              data-aos-delay="400"
              className="marque  mb-3"
            >
              <marquee loop="1">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </span>
                <span>Vestibulum eget mauris ac metus lobortis iaculis.</span>
                <span>
                  Praesent quis dolor egestas, ullamcorper mi sit amet, commodo
                  tellus.
                </span>
              </marquee>
            </div>
          </>
        )} */}
      <Col md={9}>
        {" "}
        <ListPage type="in_play" heading="InPlay" />
      </Col>

      {/* <ListPage type="today" heading="Upcoming" /> */}
      {/* <div
      data-aos="zoom-in-down"
      data-aos-easing="ease"
      data-aos-delay="400"
      className=" br-6 mb-3"
    >
      <h2 className="mb-3">
        <img src={live} /> Inplay{" "}
        <span className="float-end">
          <img src={UpcomingDark} /> Upcoming
        </span>
      </h2>

      <div className="leagueBox">
        <div className="title">
          <Row>
            <Col md={7}>
              <span className="live">LIVE</span>
              <img
                src={BatBlue}
                style={{ maxWidth: "20px" }}
              />{" "}
              Cricket
            </Col>
            <Col md={5} className="text-dark text-center">
              <Row>
                <Col className="text-center" md={4}>
                  1
                </Col>
                <Col className="text-center" md={4}>
                  2
                </Col>
                <Col className="text-center" md={4}>
                  3
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="laegueRow">
          <Row>
            <Col md={7} className="text-blue">
              <img src={trophy} style={{ maxWidth: "20px" }} />{" "}
              Twenty20 ICC World Cup
            </Col>
            <Col md={5} className="text-dark text-center">
              <img src={telecase} style={{ maxWidth: "25px" }} />
            </Col>
          </Row>
        </div>
        <div className="laegueRow active">
          <Row>
            <Col md={7}>
              <Row className="mb-1">
                <Col md={5}>
                  <img
                    src={IndFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>175/7</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <img
                    src={PakFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>176/9 (15.5)</Col>
              </Row>
            </Col>
            <Col md={5} className="text-dark text-center">
              <Row className="mx-0">
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="whiteBoxes">-</div>
                    <div className="whiteBoxes">-</div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="laegueRow">
          <Row>
            <Col md={7}>
              <Row className="mb-1">
                <Col md={5}>
                  <img
                    src={IndFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>175/7</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <img
                    src={PakFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>176/9 (15.5)</Col>
              </Row>
            </Col>
            <Col md={5} className="text-dark text-center">
              <Row className="mx-0">
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="whiteBoxes">-</div>
                    <div className="whiteBoxes">-</div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="laegueRow greyBG">
          <Row>
            <Col md={7}>
              <Row className="mb-1">
                <Col md={5}>
                  <img
                    src={IndFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>175/7</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <img
                    src={PakFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>176/9 (15.5)</Col>
              </Row>
            </Col>
            <Col md={5} className="text-dark text-center">
              <Row className="mx-0">
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="whiteBoxes">-</div>
                    <div className="whiteBoxes">-</div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>

    <div
      data-aos="zoom-in-left"
      data-aos-easing="ease"
      data-aos-delay="400"
      className="m-banner mb-3"
    >
      <img src={MBanner} />
    </div>
    <div
      data-aos="zoom-in-right"
      data-aos-easing="ease"
      data-aos-delay="400"
      className=" mb-3"
    >
      <h2 className="mb-3">
        <img src={upcoming} /> Upcoming{" "}
        <span className="float-end">
          <img src={InPlayDark} /> Inplay
        </span>
      </h2>

      <div className="leagueBox">
        <div className="title">
          <Row>
            <Col md={7}>
              <span className="live">LIVE</span>
              <img
                src={Racket}
                style={{ maxWidth: "20px" }}
              />{" "}
              Tennis
            </Col>
            <Col md={5} className="text-dark text-center">
              <Row>
                <Col className="text-center" md={4}>
                  1
                </Col>
                <Col className="text-center" md={4}>
                  2
                </Col>
                <Col className="text-center" md={4}>
                  3
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="laegueRow">
          <Row>
            <Col md={7} className="text-blue">
              <img src={trophy} style={{ maxWidth: "20px" }} />{" "}
              Twenty20 ICC World Cup
            </Col>
            <Col md={5} className="text-dark text-center">
              <img src={telecase} style={{ maxWidth: "25px" }} />
            </Col>
          </Row>
        </div>
        <div className="laegueRow active">
          <Row>
            <Col md={7}>
              <Row className="mb-1">
                <Col md={5}>
                  <img
                    src={IndFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>175/7</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <img
                    src={PakFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>176/9 (15.5)</Col>
              </Row>
            </Col>
            <Col md={5} className="text-dark text-center">
              <Row className="mx-0">
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="whiteBoxes">-</div>
                    <div className="whiteBoxes">-</div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="laegueRow">
          <Row>
            <Col md={7}>
              <Row className="mb-1">
                <Col md={5}>
                  <img
                    src={IndFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>175/7</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <img
                    src={PakFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>176/9 (15.5)</Col>
              </Row>
            </Col>
            <Col md={5} className="text-dark text-center">
              <Row className="mx-0">
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="whiteBoxes">-</div>
                    <div className="whiteBoxes">-</div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="laegueRow greyBG">
          <Row>
            <Col md={7}>
              <Row className="mb-1">
                <Col md={5}>
                  <img
                    src={IndFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>175/7</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <img
                    src={PakFlag}
                    style={{ maxWidth: "20px" }}
                  />{" "}
                  India
                </Col>
                <Col md={7}>176/9 (15.5)</Col>
              </Row>
            </Col>
            <Col md={5} className="text-dark text-center">
              <Row className="mx-0">
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="whiteBoxes">-</div>
                    <div className="whiteBoxes">-</div>
                  </div>
                </Col>
                <Col className="text-center px-0" md={4}>
                  <div className="d-flex">
                    <div className="bluBoxs">
                      3.63<span>14532</span>
                    </div>
                    <div className="redBoxs">
                      3.63<span>14532</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div> */}
    </Layout>
  );
};

export default Home;
