import React, { useEffect, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import trophy from "../img/trophy.png";
import Dice from "../img/dise.png";
const OpenBets = () => {
  const [data, setData] = useState([]);

  const getCurrentBets = async () => {
    const { status, data: response_users } = await apiGet(apiPath.currentBets);
    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
      }
    }
  };

  useEffect(() => {
    getCurrentBets();
  }, []);
  return (
    <Accordion defaultActiveKey="0" className="rightAccordian">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Matched Bets</Accordion.Header>
        <Accordion.Body>
          {data?.map((res, index) => {
            return (
              <div>
                <div className="greyBG">
                  <Form.Check
                    type="checkbox"
                    label="Average Odds"
                    className="blue-check"
                  />
                  <p className="px-2 f-12 mb-2">
                    <img src={trophy} style={{ maxWidth: "17px" }} />{" "}
                    {res?.matchName}
                  </p>
                  <p className="px-2 f-12 pb-2 mb-0">
                    <img src={Dice} style={{ maxWidth: "17px" }} />{" "}
                    {res?.teamName}
                  </p>
                </div>
                <div className="blueBG text-center">
                  <Row>
                    <Col>
                      {res?.bhav}
                      <span>Odds</span>
                    </Col>
                    <Col>
                      {res?.amount}
                      <span>Stake</span>
                    </Col>
                    <Col>
                      {res?.profitAmount}
                      <span>Profit</span>
                    </Col>
                  </Row>
                </div>{" "}
              </div>
            );
          })}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default OpenBets;
