import { render, screen } from "@testing-library/react";
import React, { useContext, useEffect, useState, useRef } from "react";

import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { FloatingLabel } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IoIosArrowBack } from "react-icons/io";
import loginImg from "../img/login-img.png";
import logo from "../img/logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { Controller, useForm } from "react-hook-form";
import {
  FaUserAlt,
  FaLock,
  FaShieldAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
function Register() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  let { loginUser, user } = useContext(AuthContext);

  const { registerUser } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(true);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(true);
  const handleShow = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const {
    register,
    unregister,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      countryCode: 91,
      uniqueId: Math.random() * 10000,
    },
  });
  const { search } = useLocation();
  let code = search?.split("=")[1];
  let codeCondition = search?.split("=")?.includes("?referral_code")
    ? true
    : false;

  useEffect(() => {
    if (codeCondition) {
      setValue("refreralCode", code);
    }
  }, [code, codeCondition]);

  // const [isLoader, setLoader] = useState(false);
  const onSubmit = async (body) => {
    if (parseInt(body.validateCode) === parseInt(getValidateCode)) {
      registerUser({ body, inputRef });
    }
  };

  const [getValidateCode, setValidateCode] = useState("");
  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 120px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 0, 130);
    setValidateCode(code);
  };
    useEffect(() => {
      changeCode();
    }, []);
  return (
    <>
      <div className="login">
        <Container className="h-100 align-middle">
          <Row className="h-100 align-items-center align-middle justify-content-center">
            <Col md={11}>
              <Row>
                <Col md={7} className="text-start">
                  <img
                    data-aos="zoom-in-down"
                    src={loginImg}
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col md={5}>
                  <div data-aos="flip-up" className="text-center mt-5 mb-4">
                    <img src={logo} style={{ maxWidth: "200px" }} />
                  </div>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div data-aos="zoom-in" className="loginForm">
                      <Form.Label>Username</Form.Label>{" "}
                      <Form.Group className="mb-2">
                        <Form.Control
                          {...register("username", {
                            required: "Please enter Username",
                            minLength: {
                              value: 3,
                              message:
                                "Username should contain atleast 3 characters",
                            },
                            maxLength: {
                              value: 30,
                              message:
                                "Username should contain maximum 30 characters",
                            },
                            pattern: {
                              value: /^[\w-_.]{0,10}$/,
                              message: "Please enter valid Username",
                            },
                          })}
                          autocomplete="off"
                          type="text"
                          placeholder="Username"
                        />{" "}
                        {errors.username && errors.username.message && (
                          <label className="invalid-feedback text-left">
                            {errors.username.message}
                          </label>
                        )}
                      </Form.Group>
                      <Form.Label>Phone</Form.Label>
                      <FloatingLabel controlId="floatingInput" label="">
                        <Controller
                          control={control}
                          name="mobile"
                          rules={{
                            required: "Please enter mobile number.",
                            validate: (value) => {
                              let inputValue = value
                                ?.toString()
                                ?.slice(
                                  inputRef?.current?.state?.selectedCountry
                                    ?.countryCode?.length,
                                  value?.length
                                );
                              if (inputValue?.length < 10) {
                                return "Mobile number must contain 10 digit";
                              } else if (inputValue?.length > 12) {
                                return "Mobile number should not exceed 12 digit";
                              } else {
                                return true;
                              }
                            },
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <>
                              <PhoneInput
                                {...field}
                                inputExtraProps={{
                                  ref,
                                  required: true,
                                  autoFocus: true,
                                }}
                                ref={inputRef}
                                inputStyle={{
                                  width: "100%",
                                  height: "38px",
                                }}
                                countryCodeEditable={false}
                                disableDropdown={true}
                                country={"in"}
                              />
                            </>
                          )}
                        />
                      </FloatingLabel>
                      {errors?.mobile?.message && (
                        <label className="invalid-feedback text-left">
                          {errors?.mobile?.message}
                        </label>
                      )}
                      <Form.Label>Password</Form.Label>
                      <Form.Group className="mb-2 position-relative">
                        <Form.Control
                          type={!showPassword ? "text" : "password"}
                          placeholder="Password"
                          maxLength={15}
                          {...register("password", {
                            required: "Please enter password",
                            minLength: {
                              value: 8,
                              message:
                                "Password should contain atleast 8 characters",
                            },
                            maxLength: {
                              value: 16,
                              message:
                                "Password should contain maximum 16 characters",
                            },
                          })}
                        />
                        <Button
                          className=" w-25 position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                          onClick={() => handleShow()}
                        >
                          {!showPassword ? (
                            <FaEye className="opacity-100 fs-5" size="17" />
                          ) : (
                            <FaEyeSlash
                              className="opacity-100 fs-5"
                              size="17"
                            />
                          )}
                        </Button>
                      </Form.Group>{" "}
                      {errors.password && errors?.password?.message && (
                        <label className="invalid-feedback text-left">
                          {errors.password.message}
                        </label>
                      )}
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Group className="mb-2 position-relative">
                        <Form.Control
                          type={!showPasswordConfirm ? "text" : "password"}
                          placeholder="confirmPassword"
                          maxLength={15}
                          {...register("confirmPassword", {
                            required: "Please enter confirm password",
                            validate: (value) => {
                              if (!isEmpty(watch("password"))) {
                                if (value == watch("password")) {
                                  return true;
                                } else {
                                  return "password and confirm password does not match.";
                                }
                              }
                            },
                          })}
                        />
                        <Button
                          className=" w-25 position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                          onClick={() => handleShowConfirm()}
                        >
                          {!showPasswordConfirm ? (
                            <FaEye className="opacity-100 fs-5" size="17" />
                          ) : (
                            <FaEyeSlash
                              className="opacity-100 fs-5"
                              size="17"
                            />
                          )}
                        </Button>
                      </Form.Group>{" "}
                      {errors.confirmPassword &&
                        errors?.confirmPassword?.message && (
                          <label className="invalid-feedback text-left">
                            {errors.confirmPassword.message}
                          </label>
                        )}
                      <Form.Label>ValidationCode</Form.Label>
                      <Form.Group className="position-relative mb-2">
                        <Form.Control
                          type="number"
                          keyboardType="numeric"
                          autocomplete="off"
                          maxLength="4"
                          {...register("validateCode", {
                            required: "Please enter validate code",
                            validate: {
                              validate: (value) =>
                                parseInt(value) === parseInt(getValidateCode) ||
                                "Invalid validate code",
                            },
                          })}
                          onChange={(e) => {
                            if (e.target.value.length == 4) {
                              e.target.blur();
                              unregister("validateCode", { keepValue: true });
                            }
                          }}
                          placeholder="ValidationCode"
                        />{" "}
                        <canvas
                          ref={canvasRef}
                          onClick={changeCode}
                          className="position-absolute translate-middle-y top-50 canvas-img"
                          id="authenticateImage"
                        />
                      </Form.Group>
                      {errors.validateCode && errors?.validateCode?.message && (
                        <label className="invalid-feedback text-left">
                          {errors.validateCode.message}
                        </label>
                      )}
                      <Form.Label>Agent Code (Optional)</Form.Label>
                      <Form.Control
                        {...register("refreralCode", {
                          // required: "Please enter agent code",
                        })}
                        autocomplete="off"
                        type="text"
                        placeholder="Agent Code"
                      />
                      {/* <p className="f-password">
                        {" "}
                        <Link
                          to="/ForgotPassword"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          Forgot Password?
                        </Link>
                      </p> */}
                      <Button type="submit" variant="primary" className="mt-3">
                        Login
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Register;
