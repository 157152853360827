import React, { useEffect, useContext } from "react";
import UpcomingDark from "../../img/upcoming-dark.png";
import live from "../../img/live.png";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import BatBlue from "../../img/bat-blue.png";
import trophy from "../../img/trophy.png";
import telecase from "../../img/telecast.png";
import { useState } from "react";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import { isEmpty } from "lodash";
import ListSection from "./ListSection";
import AuthContext from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import VSWhite from "../../img/vs-white.png";
import helper from "../../Utils/helpers";
import { io } from "socket.io-client";
const ListPage = () => {
  const { user } = useContext(AuthContext);
  const { sportType } = useParams();
  const [data, setData] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const [list, setList] = useState([]);
  const [allOdds, setAllOdds] = useState([]);
  const getOdds = async (id) => {
    let array = id
      ?.map((item) => {
        return item?.marketId;
      })
      .flat();
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${array?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setAllOdds(response_users.results);
        }
      }
    }
  };
  const getData = async () => {
    const { status, data } = await apiGet(apiPath.matchList, {
      gameType: isEmpty(sportType) ? "All" : sportType,
    });
    if (data?.success) {
      let obj = {};
      if (isEmpty(sportType)) {
        obj = {
          cricket:
            sortBy(
              data?.results?.filter(
                (res) => res?.gameType == "cricket" && res?.status == "in_play"
              )
            ) || [],
          tennis:
            sortBy(
              data?.results?.filter(
                (res) => res?.gameType == "tennis" && res?.status == "in_play"
              )
            ) || [],
          soccer:
            sortBy(
              data?.results?.filter(
                (res) => res?.gameType == "soccer" && res?.status == "in_play"
              )
            ) || [],
          upcomingHome:
            sortBy(
              data?.results?.filter(
                (res) => res?.gameType == "cricket" && res?.status == "active"
              )
            ) || [],
          allInplay:
            sortBy(data?.results?.filter((res) => res?.status == "in_play")) ||
            [],
        };
      } else {
        obj = {
          allInplay:
            sortBy(data?.results?.filter((res) => res?.status == "in_play")) ||
            [],
          cricket:
            sortBy(
              data?.results?.filter(
                (res) => res?.gameType == "cricket" && res?.status == "in_play"
              )
            ) || [],
          tennis:
            sortBy(
              data?.results?.filter(
                (res) => res?.gameType == "tennis" && res?.status == "in_play"
              )
            ) || [],
          soccer:
            sortBy(
              data?.results?.filter(
                (res) => res?.gameType == "soccer" && res?.status == "in_play"
              )
            ) || [],
          cricketUpcoming:
            sortBy(
              data?.results?.filter(
                (res) => res?.gameType == "cricket" && res?.status == "active"
              )
            ) || [],
          tennisUpcoming:
            sortBy(
              data?.results?.filter(
                (res) => res?.gameType == "tennis" && res?.status == "active"
              )
            ) || [],
          soccerUpcoming:
            sortBy(
              data?.results?.filter(
                (res) => res?.gameType == "soccer" && res?.status == "active"
              )
            ) || [],
        };
      }
      getOdds(data?.results);
      setData(obj);
      setList(data?.results);
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      let interval = setInterval(() => {
        getOdds(list);
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [list]);

  const sortBy = (array) => {
    return array?.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  };

  useEffect(() => {
    getData();
  }, [sportType]);

  let lengthObj = {
    1: 12,
    2: 6,
    3: 4,
  };

  // Socket
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = !isEmpty(user) ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${"https://bdy247.com:5000"}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(`${"https://bdy247.com:5000"}?user_id=${randomId}`, {
          transports: ["websocket"],
          forceNew: true,
        });
      }
      setSocketObj(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });
  return (
    <>
      {data?.allInplay?.length >= 2 && (
        <div
          data-aos="zoom-in-up"
          data-aos-easing="ease"
          data-aos-delay="400"
          className="br-6 p-3 mb-3"
        >
          <Carousel>
            <Carousel.Item>
              <Row>
                {data?.allInplay?.slice(0, 2)?.map((res, index, obj) => {
                  return (
                    <Col md={lengthObj[obj?.length]}>
                      <div className="matchBox br-6">
                        <h4>
                          {res?.seriesName}
                          <span>
                            Today{" "}
                            {helper?.msgDateFormatTime(res?.eventDateTime)}
                          </span>
                        </h4>
                        <Row>
                          <Col className="text-center" md={5}>
                            <p>{res?.eventName?.split(" v ")[0]}</p>
                          </Col>
                          <Col className="text-center align-middle" md={2}>
                            <img
                              src={VSWhite}
                              style={{
                                maxWidth: "20px",
                                marginTop: "10px",
                              }}
                            />
                          </Col>
                          <Col className="text-center" md={5}>
                            <p>{res?.eventName?.split(" v ")[1]}</p>
                          </Col>
                        </Row>
                        <h5>Match Result</h5>
                        <Row className="">
                          <Col className="text-center pe-0" md={4}>
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                          <Col
                            className="text-center  align-middle px-1"
                            md={4}
                          >
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                          <Col className="text-center  ps-0" md={4}>
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Carousel.Item>
            {/* <Carousel.Item>
                  <Row>
                    <Col md={4}>
                      <div className="matchBox br-6">
                        <h4>
                          Twenty20 ICC World Cup<span>Today 8:00 PM</span>
                        </h4>
                        <Row>
                          <Col className="text-center" md={4}>
                            <img src={IndFlag} style={{ maxWidth: "30px" }} />
                            <p>India</p>
                          </Col>
                          <Col className="text-center align-middle" md={4}>
                            <img
                              src={VSWhite}
                              style={{
                                maxWidth: "20px",
                                marginTop: "10px",
                              }}
                            />
                          </Col>
                          <Col className="text-center" md={4}>
                            <img src={PakFlag} style={{ maxWidth: "30px" }} />
                            <p>Pakistan</p>
                          </Col>
                        </Row>
                        <h5>Match Result</h5>
                        <Row className="">
                          <Col className="text-center pe-0" md={4}>
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                          <Col
                            className="text-center  align-middle px-1"
                            md={4}
                          >
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                          <Col className="text-center  ps-0" md={4}>
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="matchBox br-6">
                        <h4>
                          Twenty20 ICC World Cup<span>Today 8:00 PM</span>
                        </h4>
                        <Row>
                          <Col className="text-center" md={4}>
                            <img src={IndFlag} style={{ maxWidth: "30px" }} />
                            <p>India</p>
                          </Col>
                          <Col className="text-center align-middle" md={4}>
                            <img
                              src={VSWhite}
                              style={{
                                maxWidth: "20px",
                                marginTop: "10px",
                              }}
                            />
                          </Col>
                          <Col className="text-center" md={4}>
                            <img src={PakFlag} style={{ maxWidth: "30px" }} />
                            <p>Pakistan</p>
                          </Col>
                        </Row>
                        <h5>Match Result</h5>
                        <Row className="">
                          <Col className="text-center pe-0" md={4}>
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                          <Col
                            className="text-center  align-middle px-1"
                            md={4}
                          >
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                          <Col className="text-center  ps-0" md={4}>
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="matchBox br-6">
                        <h4>
                          Twenty20 ICC World Cup<span>Today 8:00 PM</span>
                        </h4>
                        <Row>
                          <Col className="text-center" md={4}>
                            <img src={IndFlag} style={{ maxWidth: "30px" }} />
                            <p>India</p>
                          </Col>
                          <Col className="text-center align-middle" md={4}>
                            <img
                              src={VSWhite}
                              style={{
                                maxWidth: "20px",
                                marginTop: "10px",
                              }}
                            />
                          </Col>
                          <Col className="text-center" md={4}>
                            <img src={PakFlag} style={{ maxWidth: "30px" }} />
                            <p>Pakistan</p>
                          </Col>
                        </Row>
                        <h5>Match Result</h5>
                        <Row className="">
                          <Col className="text-center pe-0" md={4}>
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                          <Col
                            className="text-center  align-middle px-1"
                            md={4}
                          >
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                          <Col className="text-center  ps-0" md={4}>
                            <div className="oddNumber d-flex">
                              <span className="w-25">1</span>
                              <span className="w-75 text-end">4.22</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item> */}
          </Carousel>
        </div>
      )}
      {(data?.soccer?.length > 0 ||
        data?.cricket?.length > 0 ||
        data?.tennis?.length > 0) && (
        <div
          data-aos="zoom-in-down"
          data-aos-easing="ease"
          data-aos-delay="400"
          className=" br-6 mb-3"
        >
          <h2 className="mb-3">
            <img src={live} />
            InPlay
            <span className="float-end">
              <img src={UpcomingDark} /> Inplay
            </span>
          </h2>
          {data?.cricket?.length > 0 && (
            <ListSection
              heading="Cricket"
              data={data?.cricket}
              allOdds={allOdds}
            />
          )}{" "}
          {data?.tennis?.length > 0 && (
            <ListSection
              heading="Tennis"
              data={data?.tennis}
              allOdds={allOdds}
            />
          )}{" "}
          {data?.soccer?.length > 0 && (
            <ListSection
              heading="Soccer"
              data={data?.soccer}
              allOdds={allOdds}
            />
          )}
        </div>
      )}
      <div
        data-aos="zoom-in-down"
        data-aos-easing="ease"
        data-aos-delay="400"
        className=" br-6 mb-3"
      >
        <h2 className="mb-3">
          Upcoming
          <span className="float-end">
            <img src={UpcomingDark} /> Upcoming
          </span>
        </h2>
        {isEmpty(sportType) && data?.upcomingHome?.length > 0 && (
          <ListSection
            heading="Cricket"
            data={data?.upcomingHome}
            allOdds={allOdds}
          />
        )}{" "}
        {!isEmpty(sportType) && (
          <>
            {data?.cricketUpcoming?.length > 0 && (
              <ListSection
                data={data?.cricketUpcoming}
                allOdds={allOdds}
                heading="Cricket"
              />
            )}
            {data?.tennisUpcoming?.length > 0 && (
              <ListSection
                data={data?.tennisUpcoming}
                allOdds={allOdds}
                heading="Tennis"
              />
            )}
            {data?.soccerUpcoming?.length > 0 && (
              <ListSection
                data={data?.soccerUpcoming}
                allOdds={allOdds}
                heading="Soccer"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ListPage;
