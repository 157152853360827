import { render, screen } from '@testing-library/react';
import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link } from "react-router-dom";

import Header from '../component/header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CasinDetails from '../img/game.jpg.webp';

function CasinoDetail() {
    
    return (
        <>
         <div>
             <Container fluid p-0>
                    <Header />
                   
                    <div className='d-flex'>
                    <div className='left-menu'>
                            <h2  data-aos="zoom-in-up"  data-aos-easing="ease" data-aos-delay="400">Popular Events</h2>
                            <ul  data-aos="zoom-in-up"  data-aos-easing="ease" data-aos-delay="400" className='blocks'>
                                <li><Link to='/LeaguePage'>Eorld Cup 2022</Link></li>
                                <li><Link>ICC Womens T20 </Link></li>
                                <li><Link>South Africa T20 </Link></li>
                                <li><Link>UEFA National League</Link></li>
                                <li><Link>FIFA World Cup</Link></li>
                            </ul>
                            <div  data-aos="zoom-in-up"  data-aos-easing="ease" data-aos-delay="400" className='LeftTabs'>
                                <Tabs fill defaultActiveKey="betslip" id="uncontrolled-tab-example">
                                    <Tab eventKey="betslip" title="Live">
                                    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Soccer</Accordion.Header>
        <Accordion.Body>
        <ul>
                                <li><Link>Eorld Cup 2022</Link></li>
                                <li><Link>ICC Womens T20 </Link></li>
                                <li><Link>South Africa T20 </Link></li>
                                <li><Link>UEFA National League</Link></li>
                                <li><Link>FIFA World Cup</Link></li>
                            </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Tennis</Accordion.Header>
        <Accordion.Body>
        <ul>
                                <li><Link>Eorld Cup 2022</Link></li>
                                <li><Link>ICC Womens T20 </Link></li>
                                <li><Link>South Africa T20 </Link></li>
                                <li><Link>UEFA National League</Link></li>
                                <li><Link>FIFA World Cup</Link></li>
                            </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                                    </Tab>
                                    <Tab eventKey="openBet" title="Pre Match">dsfsdf</Tab>
                                </Tabs>
                            </div>
                        </div>
                        <div className='right-con p-3 pb-0'>
                            <Row>
                                <Col md={12}>
                                
                               <img  data-aos="zoom-in-up"  data-aos-easing="ease" data-aos-delay="400" src={CasinDetails}  style={{width:'100%'}}/>
                                
                                </Col>
                            </Row>
                            <div className='btmBox mb-0 text-center fs-12 p-3'>&copy; Copyright 2024 All Right Reserved By Betmates</div>
                        </div>
                    </div>
            </Container>
         </div>
        </>
    );
}

export default CasinoDetail;