import { render, screen } from '@testing-library/react';
import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import Header from '../component/header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import home from '../img/home.png';
import Cricket from '../img/cricket.png';
import Tennis from '../img/tennis.png';
import Basketball from '../img/basketball.png';
import Soccer from '../img/soccer.png';
import Golf from '../img/golf.png';
import Politics from '../img/poltcs.png';
import Baseball from '../img/baseball.png';
import lbanner from '../img/left-banner.png';
import IndFlag from '../img/flag-india.png';
import PakFlag from '../img/flag-Pak.png';
import UpcomingDark from '../img/upcoming-dark.png';
import live from '../img/live.png';
import trophy from '../img/trophy.png';
import Racket from '../img/racket.png';
import RightBanner from '../img/right-banner.png';
import RightBanner1 from '../img/right-banner-1.png';
import Dice from '../img/dise.png';
import { Link } from 'react-router-dom';

import Market from '../img/market.png';
import share from '../img/share.png';
import toss from '../img/toss.png';
import Sign from '../img/sign.png';
import BlueTrophy from '../img/blue-trophy.png';
function LeaguePage() {
    return (
        <>
         <div>
             <Container fluid p-0>
                    <Header />
                    <div className='d-flex'>
                    <div className='left-menu'>
                            <h2  data-aos="zoom-in-up"  data-aos-easing="ease" data-aos-delay="400">Popular Events</h2>
                            <ul  data-aos="zoom-in-up"  data-aos-easing="ease" data-aos-delay="400" className='blocks'>
                                <li><Link to='/LeaguePage'>Eorld Cup 2022</Link></li>
                                <li><Link to='/MatchPage'>ICC Womens T20 </Link></li>
                                <li><Link>South Africa T20 </Link></li>
                                <li><Link>UEFA National League</Link></li>
                                <li><Link>FIFA World Cup</Link></li>
                            </ul>
                            <div  data-aos="zoom-in-up"  data-aos-easing="ease" data-aos-delay="400" className='LeftTabs'>
                                <Tabs fill defaultActiveKey="betslip" id="uncontrolled-tab-example">
                                    <Tab eventKey="betslip" title="Live">
                                    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Soccer</Accordion.Header>
        <Accordion.Body>
        <ul>
                                <li><Link>Eorld Cup 2022</Link></li>
                                <li><Link>ICC Womens T20 </Link></li>
                                <li><Link>South Africa T20 </Link></li>
                                <li><Link>UEFA National League</Link></li>
                                <li><Link>FIFA World Cup</Link></li>
                            </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Tennis</Accordion.Header>
        <Accordion.Body>
        <ul>
                                <li><Link>Eorld Cup 2022</Link></li>
                                <li><Link>ICC Womens T20 </Link></li>
                                <li><Link>South Africa T20 </Link></li>
                                <li><Link>UEFA National League</Link></li>
                                <li><Link>FIFA World Cup</Link></li>
                            </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                                    </Tab>
                                    <Tab eventKey="openBet" title="Pre Match">dsfsdf</Tab>
                                </Tabs>
                            </div>
                        </div>
                        <div className='right-con p-3 pb-0'>
                            <Row>
                                <Col md={9}>
                                
                                    <div className='mb-4'>
                                            <h2 data-aos="zoom-in-up" className='mb-3'>
                                                <img src={Market}/> Markets <span className='float-end'><img src={share} /> Share</span>
                                            </h2>

                                            <div data-aos="zoom-in-up" className='leagueBox mb-3'>
                                                <div className='title'>
                                                    <Row>
                                                        <Col md={7}>
                                                        <span className='live'>LIVE</span>
                                                            <img src={BlueTrophy} style={{maxWidth:'20px'}}/> ICC World Cup T20 Winner 2024
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row>
                                                            <Col className='text-center' md={4}>Back</Col>
                                                            <Col className='text-center' md={4}></Col>
                                                            <Col className='text-center' md={4}>Lay</Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>


                                                <div className='laegueRow'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} /> <span className='redValue'>-65</span> Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                                <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='whiteBoxes'>-</div>
                                                                    <div className='whiteBoxes'>-</div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='laegueRow greyBG'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} /> <span className='redValue'>-65</span> Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                                <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='whiteBoxes'>-</div>
                                                                    <div className='whiteBoxes'>-</div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='laegueRow'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} /> <span className='redValue'>-65</span> Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                                <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='whiteBoxes'>-</div>
                                                                    <div className='whiteBoxes'>-</div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='laegueRow greyBG'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} /> <span className='greenValue'>65</span> Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                                <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='whiteBoxes'>-</div>
                                                                    <div className='whiteBoxes'>-</div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='laegueRow'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} /> <span className='redValue'>-65</span> Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                                <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='whiteBoxes'>-</div>
                                                                    <div className='whiteBoxes'>-</div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='laegueRow greyBG'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} /> <span className='redValue'>-65</span> Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                                <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='whiteBoxes'>-</div>
                                                                    <div className='whiteBoxes'>-</div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='laegueRow'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} /> <span className='redValue'>-65</span> Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                                <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='whiteBoxes'>-</div>
                                                                    <div className='whiteBoxes'>-</div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='laegueRow greyBG'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} /> <span className='redValue'>-65</span> Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                                <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='whiteBoxes'>-</div>
                                                                    <div className='whiteBoxes'>-</div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='laegueRow'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} /> <span className='redValue'>-65</span> Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                                <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='whiteBoxes'>-</div>
                                                                    <div className='whiteBoxes'>-</div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='laegueRow greyBG'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} /> <span className='redValue'>-65</span> Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                                <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='whiteBoxes'>-</div>
                                                                    <div className='whiteBoxes'>-</div>
                                                                </div>
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                            <div className='d-flex'>
                                                                    <div className='bluBoxs'>3.63<span>14532</span></div>
                                                                    <div className='redBoxs'>3.63<span>14532</span></div>
                                                                </div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>
                                                </div>

                                                <div data-aos="zoom-in-up" className='leagueBox mt-5 mb-3'>
                                                <div className='PremiumBox'> <span className='upcomings'>UPCOMING</span>Premium Bookmaker (0% Commission)</div>
                                                <div className='title mt-1'>
                                                    <Row>
                                                        <Col md={7}>
                                                       
                                                            <img src={toss} style={{maxWidth:'20px'}}/> To Win the Toss
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row>
                                                            <Col className='text-center px-0' md={4}>Back</Col>
                                                            <Col className='text-center  px-0' md={4}></Col>
                                                            <Col className='text-center px-0' md={4}>Lay</Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>


                                                <div className='laegueRow'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={PakFlag} style={{maxWidth:'20px'}} />  Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center  px-0'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                               
                                                                    <div className='bluBoxs d-inline-block'>3.63<span>14532</span></div>
                                                               
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                           
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                           
                                                                   <div className='redBoxs  d-inline-block'>3.63<span>14532</span></div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='laegueRow'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} />  India
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center px-0'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                               
                                                               <div className='bluBoxs d-inline-block'>3.63<span>14532</span></div>
                                                          
                                                       </Col>
                                                       <Col className='text-center px-0' md={4}>
                                                      
                                                       </Col>
                                                       <Col className='text-center px-0' md={4}>
                                                      
                                                              <div className='redBoxs  d-inline-block'>3.63<span>14532</span></div>
                                                       </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                </div>

                                                <div data-aos="zoom-in-up" className='leagueBox mt-5 mb-3'>
                                                <div data-aos="zoom-in-up" className='PremiumBox'> <span className='upcomings'>UPCOMING</span>Premium Bookmaker (0% Commission)</div>
                                                <div data-aos="zoom-in-up" className='title mt-1'>
                                                    <Row>
                                                        <Col md={7}>
                                                       
                                                            <img src={Sign} style={{maxWidth:'20px'}}/> To Win the Toss
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center'>
                                                            <Row>
                                                            <Col className='text-center px-0' md={4}>Back</Col>
                                                            <Col className='text-center  px-0' md={4}></Col>
                                                            <Col className='text-center px-0' md={4}>Lay</Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>


                                                <div  data-aos="zoom-in-up" className='laegueRow'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={PakFlag} style={{maxWidth:'20px'}} />  Pakistan
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center  px-0'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                               
                                                                    <div className='bluBoxs d-inline-block'>3.63<span>14532</span></div>
                                                               
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                           
                                                            </Col>
                                                            <Col className='text-center px-0' md={4}>
                                                           
                                                                   <div className='redBoxs  d-inline-block'>3.63<span>14532</span></div>
                                                            </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div data-aos="zoom-in-up" className='laegueRow'>
                                                <Row>
                                                        <Col md={7} className='lh-43'>
                                                            <img src={IndFlag} style={{maxWidth:'20px'}} />  India
                                                        </Col>
                                                        <Col md={5} className='text-dark text-center px-0'>
                                                            <Row className='mx-0'>
                                                            <Col className='text-center px-0' md={4}>
                                                               
                                                               <div className='bluBoxs d-inline-block'>3.63<span>14532</span></div>
                                                          
                                                       </Col>
                                                       <Col className='text-center px-0' md={4}>
                                                      
                                                       </Col>
                                                       <Col className='text-center px-0' md={4}>
                                                      
                                                              <div className='redBoxs  d-inline-block'>3.63<span>14532</span></div>
                                                       </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>

                                                </div>
                                       
                                    </div>


                                </Col>
                                <Col md={3}>
                                    <div data-aos='fade-up-left' className='lb-box br-6 p-3 mb-3'>
                                    <Tabs defaultActiveKey="betslip" id="uncontrolled-tab-example" className="mb-3">
                                        <Tab eventKey="betslip" title="Bet Slip">
                                           <p className='mb-2 text-blue f-500'><img src={trophy} style={{maxWidth:'20px'}}/> Twenty20.ICC World Cup</p>
                                           <p className='mb-0'><img src={IndFlag} style={{maxWidth:'20px'}}/> India</p>
                                           <hr className='lgrey'/>
                                           <div className='d-flex'>
                                                <div className='me-2 input-fix'>
                                                <Form.Label htmlFor="inputPassword5">Odd</Form.Label>
                                                <Form.Control className='me-2' aria-label="First name" />
                                                </div>
                                                <div className='input-fix'>
                                                <Form.Label htmlFor="inputPassword5">Stake</Form.Label>
                                                <Form.Control type='number' className='me-2' aria-label="First name" />
                                                </div>
                                           </div>
                                           <p className='infoTxt'>or Choose Your Stake Size</p>
                                           <hr className='lgrey'/>
                                           <span className='stake-value me-1'>+25</span>
                                           <span className='stake-value me-1 active'>+50</span>  
                                           <span className='stake-value me-1'>+100</span>
                                           <span className='stake-value me-1'>+1000</span>
                                           <span className='stake-value me-1 mt-2'>+10000</span>
                                           <span className='stake-value me-1 mt-2'>+25000</span>
                                           <hr className='lgrey'/>
                                           <Form.Check className='switch' type="switch" id="custom-switch" label="Confirm bets before placing" />
                                           <Button variant="primary" className='btn-red me-1' size="sm">Cancel</Button>
                                           <Button variant="secondary" className='btn-green' size="sm">Place bid <span>(Profit:440)</span></Button>
                                           <p className='infoTxt'>Min Bet:20 Max Bet: 1250 Max winning:12500</p>
                                           
                                        </Tab>
                                        <Tab eventKey="openBet" title="Open Bet">
                                        <Accordion defaultActiveKey="0" className='rightAccordian'>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Unmatched Bets</Accordion.Header>
        <Accordion.Body>
        <div className='greyBG'>
              <Form.Check  type='checkbox' label='Average Odds' className='blue-check'  />
              <p className='px-2 f-12 mb-2'><img src={trophy} style={{maxWidth:'17px'}}/> Twenty20. ICC World Cup</p>
              <p className='px-2 f-12 pb-2 mb-0'><img src={Dice} style={{maxWidth:'17px'}}/> India - Winner - 2024</p>
            </div>

            <div className='blueBG text-center'>
                <Row>
                    <Col>3.2<span>Odds</span></Col>
                    <Col>25<span>Stake</span></Col>
                    <Col>35<span>Profit</span></Col>
                </Row>
            </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Matched Bets</Accordion.Header>
        <Accordion.Body>
            <div className='greyBG'>
              <Form.Check  type='checkbox' label='Average Odds' className='blue-check'  />
              <p className='px-2 f-12 mb-2'><img src={trophy} style={{maxWidth:'17px'}}/> Twenty20. ICC World Cup</p>
              <p className='px-2 f-12 pb-2 mb-0 text-dark'><img src={Dice} style={{maxWidth:'17px'}}/> India - Winner - 2024</p>
            </div>

            <div className='blueBG text-center'>
                <Row>
                    <Col>3.2<span>Odds</span></Col>
                    <Col>25<span>Stake</span></Col>
                    <Col>35<span>Profit</span></Col>
                </Row>
            </div>
             
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Premuim Bookmaker</Accordion.Header>
        <Accordion.Body>
        <div className='greyBG'>
              <Form.Check  type='checkbox' label='Average Odds' className='blue-check'  />
              <p className='px-2 f-12 mb-2'><img src={trophy} style={{maxWidth:'17px'}}/> Twenty20. ICC World Cup</p>
              <p className='px-2 f-12 pb-2 mb-0 text-dark'><img src={Dice} style={{maxWidth:'17px'}}/> India - Winner - 2024</p>
            </div>

            <div className='blueBG text-center'>
                <Row>
                    <Col>3.2<span>Odds</span></Col>
                    <Col>25<span>Stake</span></Col>
                    <Col>35<span>Profit</span></Col>
                </Row>
            </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Premuim SportsBook</Accordion.Header>
        <Accordion.Body>
        <div className='greyBG'>
              <Form.Check  type='checkbox' label='Average Odds' className='blue-check'  />
              <p className='px-2 f-12 mb-2'><img src={trophy} style={{maxWidth:'17px'}}/> Twenty20. ICC World Cup</p>
              <p className='px-2 f-12 pb-2 mb-0 text-dark'><img src={Dice} style={{maxWidth:'17px'}}/> India - Winner - 2024</p>
            </div>

            <div className='blueBG text-center'>
                <Row>
                    <Col>3.2<span>Odds</span></Col>
                    <Col>25<span>Stake</span></Col>
                    <Col>35<span>Profit</span></Col>
                </Row>
            </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                                        </Tab>
    
                                    </Tabs>
                                    </div>
                                    <p  data-aos="zoom-in-up"  data-aos-easing="ease" data-aos-delay="400"><img src={RightBanner} style={{maxWidth:'100%'}}/></p>
                                    <p  data-aos="zoom-in-up"  data-aos-easing="ease" data-aos-delay="400"><img src={RightBanner1}  style={{maxWidth:'100%'}}/></p>
                                </Col>
                            </Row>
                            <div className='btmBox mb-0 text-center fs-12 p-3'>&copy; Copyright 2024 All Right Reserved By Betmates</div>
                        </div>
                    </div>
            </Container>
         </div>
        </>
    );
}

export default LeaguePage;