import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";
import SportsTab from "./SportsTab";
import { apiGet } from "../../../Utils/apiFetch";
import apiPath from "../../../Utils/apiPath";
import { isEmpty, startCase } from "lodash";
import obj from "../../../Utils/helpers";
import objConstant from "../../../Utils/constants";
import AuthContext from "../../../context/AuthContext";
import { BiSolidRightArrow } from "react-icons/bi";
import SidebarLayout from "../../../component/SidebarLayout";
import NoEvent from "../../../component/NoEvent";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import Layout from "../../Layout";
const CurrentBets = () => {
  const { user } = useContext(AuthContext);
  const [showBet, setShowBet] = useState("");
  const [exchange, setExchange] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [parly, setParly] = useState([]);
  const [bookmaker, setBookmaker] = useState([]);
  const [tab, setTab] = useState("exchange");
  const [matched, setMatched] = useState(true);
  const navigate = useNavigate();
  const handleShowBet = (data) => {
    if (data == showBet) {
      setShowBet("");
    } else {
      setShowBet(data);
    }
  };
  const getExchangeBet = async () => {
    const { status, data } = await apiGet(apiPath.getCurrentBetsExchanges);
    if (status == 200) {
      if (data?.success) {
        setExchange(data?.results);
      }
    }
  };
  const getFancyBet = async () => {
    const { status, data } = await apiGet(apiPath.getFancyBets);
    if (status == 200) {
      if (data?.success) {
        setFancy(data?.results);
      }
    }
  };
  const getBookmakerBet = async () => {
    const { status, data } = await apiGet(apiPath.getBookmakerBets);
    if (status == 200) {
      if (data?.success) {
        setBookmaker(data?.results);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      getBookmakerBet();
      getFancyBet();
      getExchangeBet();
    }
  }, [user]);

  let object = {
    exchange: matched ? exchange?.matched : exchange?.unMatched,
    fancy: fancy,
    bookmaker: bookmaker,
  };

  useEffect(() => {
    setMatched(true);
  }, [tab]);

  return (
    <Layout>
      <Col md={9}>
        <Row>
          <Col md={3}>
            <div className="pl-filter h-100">
              <div
                className="pl-filter-first d-flex flex-column"
                style={{ alignItems: "flex-start" }}
              >
                {" "}
                <SportsTab type="current" setTab={setTab} tab={tab} />
              </div>
            </div>
          </Col>
          <Col md={9}>
            <div className="balance-label position-relative">
              Current Bets
              <button className="bg-transparent border-0 text-white position-absolute end-0 pt-0">
                <RxCross2
                  className="fs-3"
                  style={{ fontWeight: "800" }}
                  onClick={() => navigate("/")}
                />
              </button>
            </div>
            <div className="pt-0 inner-sidebar-content">
              {tab == "parly" ? (
                <div style={{ padding: "0 14px" }}>
                  {parly?.map((res, index) => {
                    return (
                      <div
                        className="all-bets position-relative"
                        key={res?._id}
                      >
                        <div
                          style={{ borderBottom: "8px solid #9cb1bd" }}
                          className={
                            showBet
                              ? "current-bets-table mt-3 show"
                              : "current-bets-table mt-3"
                          }
                        >
                          <Table
                            bordered
                            className="rounded overflow-hidden bg-white mb-0 odds-table"
                          >
                            <thead>
                              <tr>
                                <th colSpan="4">
                                  <span style={{ marginRight: "50px" }}></span>
                                  Bet Placed
                                  <BiSolidRightArrow className="arrow-icon" />
                                  <span className="master-pass">
                                    {obj.dateFormat(res?.timeInserted)}
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="lay-back">
                                {!res?.isDeclared ? (
                                  <td colSpan="3" style={{ padding: "8px" }}>
                                    <div className="d-flex align-items-center">
                                      <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{
                                          fontSize: ".9285714285714286rem",
                                          fontWeight: "700",
                                          borderRadius: "9999px",
                                          width: "3.5714285714285716rem",
                                          height: "2rem",
                                          marginRight: ".5rem",
                                          background: "rgb(212 224 229/1)",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: ".2857142857142857rem",
                                            height: ".2857142857142857rem",
                                            borderRadius: "9999px",
                                            marginRight: ".25rem",
                                            background: "rgb(111 136 152/1)",
                                          }}
                                        ></div>
                                        <div
                                          style={{
                                            width: ".2857142857142857rem",
                                            height: ".2857142857142857rem",
                                            borderRadius: "9999px",
                                            marginRight: ".25rem",
                                            background: "rgb(111 136 152/1)",
                                          }}
                                        ></div>
                                        <div
                                          style={{
                                            width: ".2857142857142857rem",
                                            height: ".2857142857142857rem",
                                            borderRadius: "9999px",
                                            marginRight: ".25rem",
                                            background: "rgb(111 136 152/1)",
                                          }}
                                        ></div>
                                      </div>
                                      {res?.bets?.length == 1
                                        ? "SINGLE"
                                        : res?.bets?.length == 2
                                        ? "DOUBLE"
                                        : res?.bets?.length == 3
                                        ? "TREBLE"
                                        : "ACCMULATOR"}
                                    </div>
                                  </td>
                                ) : (
                                  <td colSpan="3">
                                    {" "}
                                    <span
                                      style={{ background: "#d4e0e5" }}
                                      className={`bet-name`}
                                      // Win -- style={{ background: "green", color: "white" }}
                                      // Loss -- style={{ background: "red", color: "white" }}
                                    >
                                      {/* Loss */}
                                      {/* Win */}
                                      ---
                                    </span>
                                    Birminghum FC
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <td>
                                  Odds req.{" "}
                                  <strong className="d-block">
                                    {parseFloat(res?.bhav).toFixed(2)}
                                  </strong>
                                </td>
                                <td>
                                  Odds Matched{" "}
                                  <strong className="d-block">
                                    {parseFloat(res?.bhav).toFixed(2)}
                                  </strong>
                                </td>
                                <td>
                                  Matched (PBU)
                                  <strong className="d-block">
                                    {res?.amount}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>Bet ID</td>
                                <td colSpan="2">{res?.matchBetId}</td>
                              </tr>
                              <tr>
                                <td>Matched Date</td>
                                <td colSpan="2">
                                  {obj.dateFormat(res?.timeInserted)}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          {showBet == res?._id && (
                            <>
                              {res?.bets?.map((item) => {
                                return (
                                  <Table className="bets-table  mb-0">
                                    <thead>
                                      <th colSpan="4">
                                        <div
                                          style={{
                                            background: "rgb(156 177 189/1)",
                                            fontSize: ".9rem",
                                          }}
                                          className="p-0 d-flex w-100 justify-content-center align-items-baseline"
                                        >
                                          {" "}
                                          <span
                                            className="master-pass"
                                            style={{ lineHeight: "normal" }}
                                          >
                                            <span style={{ fontWeight: "400" }}>
                                              {
                                                objConstant?.betCheckObj[
                                                  item?.eventType
                                                ]
                                              }{" "}
                                            </span>
                                            <BiSolidRightArrow className="arrow-icon" />
                                            {item?.matchName}{" "}
                                            <BiSolidRightArrow className="arrow-icon" />
                                            <span style={{ fontWeight: "400" }}>
                                              {item?.fancyName}
                                            </span>
                                            <BiSolidRightArrow className="arrow-icon" />
                                            <span style={{ fontWeight: "400" }}>
                                              {item?.runnerName}
                                            </span>
                                          </span>
                                        </div>
                                      </th>
                                    </thead>
                                    <tbody style={{ background: "#d4e0e5" }}>
                                      <tr>
                                        <td
                                          style={{
                                            background: "#d4e0e5",
                                            borderRight: "1px solid #9cb1bd",
                                          }}
                                        >
                                          Odds req.{" "}
                                          <strong className="d-block">
                                            {parseFloat(item?.bhav).toFixed(2)}
                                          </strong>
                                        </td>
                                        <td
                                          style={{
                                            background: "#d4e0e5",
                                            borderRight: "1px solid #9cb1bd",
                                          }}
                                        >
                                          Odds Matched{" "}
                                          <strong className="d-block">
                                            {parseFloat(item?.bhav).toFixed(2)}
                                          </strong>
                                        </td>
                                        <td style={{ background: "#d4e0e5" }}>
                                          Win/Loss
                                          {item?.isDeclared ? (
                                            <>
                                              {item?.result == "loss" ? (
                                                <strong
                                                  className="d-block"
                                                  style={{ color: "red" }}
                                                >
                                                  {startCase(item?.result)}
                                                </strong>
                                              ) : (
                                                <strong
                                                  className="d-block"
                                                  style={{ color: "green" }}
                                                >
                                                  {startCase(item?.result)}
                                                </strong>
                                              )}
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                );
                              })}
                            </>
                          )}
                        </div>

                        <button
                          className={
                            showBet == res?._id
                              ? "show-hide-bet arrow-up"
                              : "show-hide-bet"
                          }
                          onClick={() => handleShowBet(res?._id)}
                        >
                          <FaAngleDown />
                        </button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div style={{ padding: "0 14px" }}>
                  {object[tab]?.length > 0 ? (
                    object[tab]?.map((item, index) => {
                      return (
                        <div className="all-bets position-relative" key={index}>
                          <div
                            className={
                              showBet
                                ? "current-bets-table mt-3 show"
                                : "current-bets-table mt-3"
                            }
                          >
                            <Table
                              bordered
                              style={{ borderRadius: ".5rem" }}
                              className="overflow-hidden bg-white mb-0 odds-table"
                            >
                              <thead>
                                <tr>
                                  <th colSpan="4">
                                    <div className="d-flex w-100 justify-content-center align-items-baseline">
                                      {
                                        objConstant?.betCheckObj[
                                          item?.eventType
                                        ]
                                      }
                                      <BiSolidRightArrow className="arrow-icon" />
                                      <span className="master-pass">
                                        {item?.matchName}{" "}
                                        <BiSolidRightArrow className="arrow-icon" />
                                        <span style={{ fontWeight: "500" }}>
                                          {tab == "exchange"
                                            ? "Match Odds"
                                            : startCase(tab)}
                                        </span>
                                      </span>

                                      {item?.runnerName && (
                                        <>
                                          <BiSolidRightArrow className="arrow-icon" />
                                          <span className="master-pass">
                                            {item?.runnerName}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="lay-back">
                                  <td colSpan="3">
                                    {" "}
                                    <span
                                      className={`bet-name ${
                                        item?.type == "No"
                                          ? "lay"
                                          : item?.type == "Yes"
                                          ? "back"
                                          : item?.betType
                                      }`}
                                    >
                                      {startCase(item?.betType || item?.type)}{" "}
                                    </span>{" "}
                                    {item?.teamName || item?.fancyName}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Odds req.{" "}
                                    <strong className="d-block">
                                      {!isEmpty(item?.betType)
                                        ? item?.bhav
                                        : `${item?.betRun}/${item?.bhav}`}
                                    </strong>
                                  </td>
                                  <td>
                                    Odds Matched{" "}
                                    <strong className="d-block">
                                      {!isEmpty(item?.betType)
                                        ? parseFloat(item?.bhav).toFixed(2)
                                        : `${item?.betRun}/${item?.bhav}`}
                                    </strong>
                                  </td>
                                  <td>
                                    Matched (PBU)
                                    <strong className="d-block">
                                      {item?.amount}
                                    </strong>
                                  </td>
                                </tr>
                                {!isEmpty(item?.betType) && (
                                  <tr className="bet-bottom">
                                    <td>Bet ID</td>
                                    <td colSpan="2">{item?.matchBetId}</td>
                                  </tr>
                                )}
                                <tr className="bet-bottom">
                                  <td>Bet Placed</td>
                                  <td colSpan="2">
                                    {obj.dateFormat(item?.timeInserted)}
                                  </td>
                                </tr>{" "}
                                <tr className="bet-bottom">
                                  <td>Matched Date</td>
                                  <td colSpan="2">
                                    {obj.dateFormat(item?.timeInserted)}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                            {showBet == item?._id && (
                              <Table className="bets-table mb-0">
                                <thead>
                                  <th className="pe-0">Bet Taken</th>
                                  <th colSpan="2">
                                    {obj.dateFormat(item?.timeInserted)}
                                  </th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      Odds req.{" "}
                                      <strong className="d-block">
                                        {!isEmpty(item?.betType)
                                          ? parseFloat(item?.bhav).toFixed(2)
                                          : `${item?.betRun}/${item?.bhav}`}
                                      </strong>
                                    </td>
                                    <td>
                                      Odds Matched{" "}
                                      <strong className="d-block">
                                        {!isEmpty(item?.betType)
                                          ? parseFloat(item?.bhav).toFixed(2)
                                          : `${item?.betRun}/${item?.bhav}`}
                                      </strong>
                                    </td>
                                    <td>
                                      Matched (PBU)
                                      <strong className="d-block">
                                        {item?.amount}
                                      </strong>
                                    </td>
                                  </tr>
                                  {(item?.betType == "lay" ||
                                    item?.type == "No") && (
                                    <tr className="bet-bottom">
                                      <td>Liability (PBU)</td>
                                      <td colSpan="2" className="text-success">
                                        <strong
                                          className="d-block"
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {" "}
                                          ({item?.profitAmount})
                                        </strong>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            )}
                          </div>

                          <button
                            className={
                              showBet == item?._id
                                ? "show-hide-bet arrow-up"
                                : "show-hide-bet"
                            }
                            onClick={() => handleShowBet(item?._id)}
                          >
                            <FaAngleDown />
                          </button>
                        </div>
                      );
                    })
                  ) : (
                    <NoEvent />
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </Layout>
  );
};

export default CurrentBets;
