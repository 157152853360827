const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
};
export default obj;

export const priceFormat = (value) => {
  return new Intl.NumberFormat().format(value);
};
export const validationRules = {
	email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
	password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
	passwordMessage: 'Password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.',
	characters: /^[a-zA-Z_ ]*$/,
	numbers: /^[0-9]*$/,
	removeWhitespace: /^[a-zA-Z0-9]+$/,
	numberNew: /^[0-9]*$/,
	numberWithDot: /^\d*(\.\d{0,10})?$/,
}
export const preventMaxInput = (e) => {
	e.target.value = e.target.value.trimStart()
	e.target.value = e.target.value.replace(/  +/g, ' ')
}
export const numberArrayCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "00", "."];
export const premiumCategoryArray = [
  { type: 1, name: "Popular" },
  { type: 3, name: "Match" },
  { type: 5, name: "Innings" },
  { type: 10, name: "Over" },
  { type: 2, name: "Batsman" },
  { type: 6, name: "Partnership" },
  { type: 7, name: "Group" },
  { type: 9, name: "Range" },
  { type: 11, name: "Head-to-Head" },
  { type: 8, name: "Player" },
];
export const priceData = [
  {
    id: 1,
    value: 10,
  },
  {
    id: 2,
    value: 50,
  },
  {
    id: 3,
    value: 100,
  },
  {
    id: 4,
    value: 200,
  },
  {
    id: 5,
    value: 1000,
  },
  {
    id: 6,
    value: 5000,
  },
];
export const headerData = [
  {
    class: "text-start",
    title: "Market",
    widht: "30%",
  },
  {
    class: "text-end",
    title: "Sports",
    widht: "",
  },
  {
    class: "text-end",
    title: "Selection",
    widht: "",
  },
  {
    class: "text-end",
    title: "Type",
    widht: "",
  },
  {
    class: "text-end",
    title: "Bet ID",
    widht: "",
  },
  {
    class: "text-end",
    title: "Bet placed",
    widht: "",
  },
  {
    class: "text-end",
    title: "Odds req.",
    widht: "",
  },
  {
    class: "text-end",
    title: "Matched",
    widht: "",
  },
  {
    class: "text-end",
    title: "Avg. odds matched",
    widht: "",
  },
  {
    class: "text-end",
    title: "Date matched",
    widht: "",
  },
];
export const betFairData = {
  data: [
    {
      bmi: "1.205777993",
      ip: 1,
      mi: 2572760,
      ms: 1,
      eti: "4",
      eid: "31859931",
      grt: "2022-11-01T08:20:58.203Z",
      rt: [
        {
          ri: 10301,
          rt: 1.49,
          bv: 9134.79,
          pr: 0,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.48,
          bv: 30747.88,
          pr: 1,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.47,
          bv: 16964.29,
          pr: 2,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.5,
          bv: 11305.92,
          pr: 0,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 10301,
          rt: 1.51,
          bv: 72391.18,
          pr: 1,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 10301,
          rt: 1.52,
          bv: 10997.18,
          pr: 2,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3,
          bv: 5652.96,
          pr: 0,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 2.98,
          bv: 43.56,
          pr: 1,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 2.96,
          bv: 36885.43,
          pr: 2,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 3.05,
          bv: 4641.48,
          pr: 0,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3.1,
          bv: 14535.58,
          pr: 1,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3.15,
          bv: 7993.98,
          pr: 2,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
      ],
    },
  ],
  messageType: "match_odds",
};
export const categoryArray = [
  { type: 1, name: "Market" },
  { type: 3, name: "Bookmakers" },
  { type: 5, name: "ManualOdds" },
  { type: 10, name: "Win Toss" },
  { type: 2, name: "Session" },
  { type: 6, name: "Over by Over Session Market" },
  { type: 7, name: "Player" },
  { type: 9, name: "Wicket" },
  { type: 11, name: "Bowler Session" },
  { type: 8, name: "Last Digit ODD" },
  { type: 4, name: "LineMarket" },
  { type: 14, name: "Premium ODDs" },
];
export const bookmakerJsonData = {
  data: [
    {
      bmi: 454109,
      ip: 1,
      mi: 2576457,
      ms: 1,
      eid: 31859931,
      grt: "2022-11-01T08:20:58.819Z",
      rt: [
        {
          ri: "4596251",
          rt: 1.49,
          bv: 520,
          pr: 0,
          af: 0,
          ib: true,
          pt: 0,
        },
        {
          ri: "4596251",
          rt: 1.51,
          bv: 537,
          pr: 0,
          af: 0,
          ib: false,
          pt: 0,
        },
        {
          ri: "4596252",
          rt: 2.13,
          bv: 520,
          pr: 0,
          af: 0,
          ib: true,
          pt: 0,
        },
        {
          ri: "4596252",
          rt: 2.14,
          bv: 537,
          pr: 0,
          af: 0,
          ib: false,
          pt: 0,
        },
      ],
    },
  ],
  messageType: "fancy",
};
export const fancyJsonData = [
  {
    _id: "636c853e02114d244506b413",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460152",
    selectionId: "4601521",
    centralizedId: "2576919",
    fancyName: "MATCH 1ST OVER RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460152,
        selectionID: 4601521,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460152,
      ip: 1,
      mi: 2576919,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:57:05.680Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b414",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460153",
    selectionId: "4601531",
    centralizedId: "2576920",
    fancyName: "6 OVER RUN IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460153,
        selectionID: 4601531,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460153,
      ip: 1,
      mi: 2576920,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:25:28.779Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b415",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460154",
    selectionId: "4601541",
    centralizedId: "2576921",
    fancyName: "6 OVER RUN ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460154,
        selectionID: 4601541,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460154,
      ip: 1,
      mi: 2576921,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:41.665Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b416",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460155",
    selectionId: "4601551",
    centralizedId: "2576922",
    fancyName: "20 OVER RUN IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460155,
        selectionID: 4601551,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460155,
      ip: 1,
      mi: 2576922,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.477Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b417",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460157",
    selectionId: "4601571",
    centralizedId: "2576923",
    fancyName: "20 OVER RUN ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460157,
        selectionID: 4601571,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460157,
      ip: 1,
      mi: 2576923,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:39.511Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b418",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460158",
    selectionId: "4601581",
    centralizedId: "2576924",
    fancyName: "FALL OF 1ST WKT IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460158,
        selectionID: 4601581,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460158,
      ip: 1,
      mi: 2576924,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:24.539Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b419",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460159",
    selectionId: "4601591",
    centralizedId: "2576925",
    fancyName: "FALL OF 1ST WKT ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460159,
        selectionID: 4601591,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460159,
      ip: 1,
      mi: 2576925,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.478Z",
      rt: [
        {
          ri: "4601591",
          rt: 24,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601591",
          rt: 24,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b41a",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460160",
    selectionId: "4601601",
    centralizedId: "2576926",
    fancyName: "1ST 2 WKT RUNS IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460160,
        selectionID: 4601601,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460160,
      ip: 1,
      mi: 2576926,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.480Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41b",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460161",
    selectionId: "4601611",
    centralizedId: "2576927",
    fancyName: "1ST 2 WKT RUNS ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460161,
        selectionID: 4601611,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460161,
      ip: 1,
      mi: 2576927,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:35.892Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41c",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460162",
    selectionId: "4601621",
    centralizedId: "2576928",
    fancyName: "1ST 3 WKT RUNS IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460162,
        selectionID: 4601621,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460162,
      ip: 1,
      mi: 2576928,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.481Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41d",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460163",
    selectionId: "4601631",
    centralizedId: "2576929",
    fancyName: "1ST 3 WKT RUNS ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460163,
        selectionID: 4601631,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460163,
      ip: 1,
      mi: 2576929,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:32.976Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41e",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460164",
    selectionId: "4601641",
    centralizedId: "2576930",
    fancyName: "1ST 4 WKT RUNS IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460164,
        selectionID: 4601641,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460164,
      ip: 1,
      mi: 2576930,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.482Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41f",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460165",
    selectionId: "4601651",
    centralizedId: "2576931",
    fancyName: "1ST 4 WKT RUNS ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460165,
        selectionID: 4601651,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460165,
      ip: 1,
      mi: 2576931,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:28.613Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b420",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460166",
    selectionId: "4601661",
    centralizedId: "2576932",
    fancyName: "1ST WKT PSHIP BOUNDARIES IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460166,
        selectionID: 4601661,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460166,
      ip: 1,
      mi: 2576932,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:04.731Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b421",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460167",
    selectionId: "4601671",
    centralizedId: "2576933",
    fancyName: "1ST WKT PSHIP BOUNDARIES ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460167,
        selectionID: 4601671,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460167,
      ip: 1,
      mi: 2576933,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.483Z",
      rt: [
        {
          ri: "4601671",
          rt: 3,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 100,
        },
        {
          ri: "4601671",
          rt: 4,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 100,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b422",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460168",
    selectionId: "4601681",
    centralizedId: "2576934",
    fancyName: "1ST WKT LOST TO IND BALLS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460168,
        selectionID: 4601681,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460168,
      ip: 1,
      mi: 2576934,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:04.736Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b423",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460169",
    selectionId: "4601691",
    centralizedId: "2576935",
    fancyName: "1ST WKT LOST TO ENG BALLS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460169,
        selectionID: 4601691,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460169,
      ip: 1,
      mi: 2576935,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.484Z",
      rt: [
        {
          ri: "4601691",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601691",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b424",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460170",
    selectionId: "4601701",
    centralizedId: "2576936",
    fancyName: "R SHARMA RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460170,
        selectionID: 4601701,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460170,
      ip: 1,
      mi: 2576936,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.486Z",
      rt: [
        {
          ri: "4601701",
          rt: 42,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601701",
          rt: 42,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b425",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460171",
    selectionId: "4601711",
    centralizedId: "2576937",
    fancyName: "KL RAHUL RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460171,
        selectionID: 4601711,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460171,
      ip: 1,
      mi: 2576937,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:11.712Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b426",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460172",
    selectionId: "4601721",
    centralizedId: "2576938",
    fancyName: "J BUTTLER RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460172,
        selectionID: 4601721,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460172,
      ip: 1,
      mi: 2576938,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.487Z",
      rt: [
        {
          ri: "4601721",
          rt: 26,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601721",
          rt: 26,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b427",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460173",
    selectionId: "4601731",
    centralizedId: "2576939",
    fancyName: "A HALES RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460173,
        selectionID: 4601731,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460173,
      ip: 1,
      mi: 2576939,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.491Z",
      rt: [
        {
          ri: "4601731",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601731",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b428",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460174",
    selectionId: "4601741",
    centralizedId: "2576940",
    fancyName: "KL RAHUL BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460174,
        selectionID: 4601741,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460174,
      ip: 1,
      mi: 2576940,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:30.740Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b429",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460175",
    selectionId: "4601751",
    centralizedId: "2576941",
    fancyName: "R SHARMA BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460175,
        selectionID: 4601751,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460175,
      ip: 1,
      mi: 2576941,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.492Z",
      rt: [
        {
          ri: "4601751",
          rt: 6,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 115,
        },
        {
          ri: "4601751",
          rt: 6,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 85,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42a",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460176",
    selectionId: "4601761",
    centralizedId: "2576942",
    fancyName: "J BUTTLER BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460176,
        selectionID: 4601761,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460176,
      ip: 1,
      mi: 2576942,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.494Z",
      rt: [
        {
          ri: "4601761",
          rt: 4,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 100,
        },
        {
          ri: "4601761",
          rt: 5,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 100,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42b",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460177",
    selectionId: "4601771",
    centralizedId: "2576943",
    fancyName: "A HALES BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460177,
        selectionID: 4601771,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460177,
      ip: 1,
      mi: 2576943,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.495Z",
      rt: [
        {
          ri: "4601771",
          rt: 3,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 100,
        },
        {
          ri: "4601771",
          rt: 4,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 100,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42c",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460178",
    selectionId: "4601781",
    centralizedId: "2576944",
    fancyName: "H M B FACE BY KL RAHUL (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460178,
        selectionID: 4601781,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460178,
      ip: 1,
      mi: 2576944,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:11.009Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b42d",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460179",
    selectionId: "4601791",
    centralizedId: "2576945",
    fancyName: "H M B FACE BY R SHARMA (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460179,
        selectionID: 4601791,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460179,
      ip: 1,
      mi: 2576945,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.499Z",
      rt: [
        {
          ri: "4601791",
          rt: 34,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601791",
          rt: 34,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42e",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460180",
    selectionId: "4601801",
    centralizedId: "2576946",
    fancyName: "H M B FACE BY J BUTTLER (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460180,
        selectionID: 4601801,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460180,
      ip: 1,
      mi: 2576946,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.500Z",
      rt: [
        {
          ri: "4601801",
          rt: 23,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601801",
          rt: 23,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42f",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460181",
    selectionId: "4601811",
    centralizedId: "2576947",
    fancyName: "H M B FACE BY A HALES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460181,
        selectionID: 4601811,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460181,
      ip: 1,
      mi: 2576947,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.501Z",
      rt: [
        {
          ri: "4601811",
          rt: 18,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601811",
          rt: 18,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b430",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460182",
    selectionId: "4601821",
    centralizedId: "2576948",
    fancyName: "TOTAL MATCH FOURS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460182,
        selectionID: 4601821,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460182,
      ip: 1,
      mi: 2576948,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:25.336Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b431",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460183",
    selectionId: "4601831",
    centralizedId: "2576949",
    fancyName: "TOTAL MATCH SIXES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460183,
        selectionID: 4601831,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460183,
      ip: 1,
      mi: 2576949,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:31.866Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b432",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460184",
    selectionId: "4601841",
    centralizedId: "2576950",
    fancyName: "TOTAL MATCH WKTS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460184,
        selectionID: 4601841,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460184,
      ip: 1,
      mi: 2576950,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:37.679Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b433",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460185",
    selectionId: "4601851",
    centralizedId: "2576951",
    fancyName: "TOTAL MATCH WIDES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460185,
        selectionID: 4601851,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460185,
      ip: 1,
      mi: 2576951,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:43.510Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b434",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460186",
    selectionId: "4601861",
    centralizedId: "2576952",
    fancyName: "TOTAL MATCH EXTRAS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460186,
        selectionID: 4601861,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460186,
      ip: 1,
      mi: 2576952,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:48.629Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b435",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460187",
    selectionId: "4601871",
    centralizedId: "2576953",
    fancyName: "TOTAL MATCH CAUGHT OUTS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460187,
        selectionID: 4601871,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460187,
      ip: 1,
      mi: 2576953,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:53.716Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b436",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460188",
    selectionId: "4601881",
    centralizedId: "2576954",
    fancyName: "TOTAL MATCH BOWLED (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460188,
        selectionID: 4601881,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460188,
      ip: 1,
      mi: 2576954,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:58.788Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b437",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460189",
    selectionId: "4601891",
    centralizedId: "2576955",
    fancyName: "TOTAL MATCH LBW (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460189,
        selectionID: 4601891,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460189,
      ip: 1,
      mi: 2576955,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:02.432Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b438",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460190",
    selectionId: "4601901",
    centralizedId: "2576956",
    fancyName: "TOTAL MATCH FIFTIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460190,
        selectionID: 4601901,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460190,
      ip: 1,
      mi: 2576956,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:06.789Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b439",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460191",
    selectionId: "4601911",
    centralizedId: "2576957",
    fancyName: "HIGHEST SCORING OVER IN MATCH (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460191,
        selectionID: 4601911,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460191,
      ip: 1,
      mi: 2576957,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:11.884Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43a",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460192",
    selectionId: "4601921",
    centralizedId: "2576958",
    fancyName: "TOP BATSMAN RUNS IN MATCH (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460192,
        selectionID: 4601921,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460192,
      ip: 1,
      mi: 2576958,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:16.930Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43b",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460193",
    selectionId: "4601931",
    centralizedId: "2576959",
    fancyName: "3 WKT OR MORE BY BOWLER IN MATCH (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460193,
        selectionID: 4601931,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460193,
      ip: 1,
      mi: 2576959,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:22.698Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43c",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460194",
    selectionId: "4601941",
    centralizedId: "2576960",
    fancyName: "6 OVER RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460194,
        selectionID: 4601941,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460194,
      ip: 1,
      mi: 2576960,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:17:45.863Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43d",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460195",
    selectionId: "4601951",
    centralizedId: "2576961",
    fancyName: "10 OVER RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460195,
        selectionID: 4601951,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460195,
      ip: 1,
      mi: 2576961,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.504Z",
      rt: [
        {
          ri: "4601951",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601951",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b43e",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460196",
    selectionId: "4601961",
    centralizedId: "2576962",
    fancyName: "15 OVER RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460196,
        selectionID: 4601961,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460196,
      ip: 1,
      mi: 2576962,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.506Z",
      rt: [
        {
          ri: "4601961",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601961",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b43f",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460197",
    selectionId: "4601971",
    centralizedId: "2576963",
    fancyName: "LAMBI RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460197,
        selectionID: 4601971,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460197,
      ip: 1,
      mi: 2576963,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.507Z",
      rt: [
        {
          ri: "4601971",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601971",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b440",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460198",
    selectionId: "4601981",
    centralizedId: "2576964",
    fancyName: "6 OVER RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460198,
        selectionID: 4601981,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460198,
      ip: 1,
      mi: 2576964,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.510Z",
      rt: [
        {
          ri: "4601981",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601981",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b441",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460199",
    selectionId: "4601991",
    centralizedId: "2576965",
    fancyName: "10 OVER RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460199,
        selectionID: 4601991,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460199,
      ip: 1,
      mi: 2576965,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.512Z",
      rt: [
        {
          ri: "4601991",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601991",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b442",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460200",
    selectionId: "4602001",
    centralizedId: "2576966",
    fancyName: "15 OVER RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460200,
        selectionID: 4602001,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460200,
      ip: 1,
      mi: 2576966,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.513Z",
      rt: [
        {
          ri: "4602001",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4602001",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b443",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460201",
    selectionId: "4602011",
    centralizedId: "2576967",
    fancyName: "LAMBI RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460201,
        selectionID: 4602011,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460201,
      ip: 1,
      mi: 2576967,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:38:50.120Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b444",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460202",
    selectionId: "4602021",
    centralizedId: "2576968",
    fancyName: "TOTAL MATCH BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460202,
        selectionID: 4602021,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460202,
      ip: 1,
      mi: 2576968,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:29.109Z",
      rt: [],
    },
  },
];
export const easyToGoCasino = [
  // {
  //   product: "EVOLUTION GAMING (S)",
  //   code: "1006",
  //   productType: "LIVE CASINO",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_blackjack-half.jpg",
  // },
  // {
  //   product: "ORIENTAL GAMING",
  //   code: "98",
  //   productType: "LIVE CASINO",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_supernowa-half.jpg",
  // },
  // {
  //   product: "GREATWALL",
  //   code: "23",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_7mojos-half.jpg",
  // },
  // {
  //   product: "918KAYA",
  //   code: "13",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_horsebook-half.jpg",
  // },
  // {
  //   product: "PLAYBOY",
  //   code: "82",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_minesweeper-half.jpg",
  // },
  // {
  //   product: "CLUBSUNCITY",
  //   code: "67",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_skytrader-half.jpg",
  // },
  {
    product: "GENESIS",
    code: "131",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_32card-half.jpg",
  },
  {
    product: "MICROGAMING",
    code: "35",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_rummy-half.jpg",
  },
  {
    product: "GAMINGSOFT SLOT",
    code: "2",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_binary-half.jpg",
  },
  // {
  //   product: "GAMEPLAY",
  //   code: "151",
  //   productType: "SLOT",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_andarBahar-half.jpg",
  // },
  {
    product: "KMG",
    code: "143",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_sicbo-half.jpg",
  },
  {
    id: 1,
    product: "KINGMAKER",
    code: "117",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_cardMatka-half.jpg",
  },
  {
    product: "CMD",
    code: "88",
    productType: "SPORTBOOK",
    productTypeCode: "3",
    image: "assets/images/home/banner_sevenUpDown-half.jpg",
  },
  {
    product: "1G Poker",
    code: "146",
    productType: "CARD AND BOARD",
    productTypeCode: "5",
    image: "assets/images/home/banner_bpoker-half.jpg",
  },
];


export const AmountArray = [
  { id: 1, amount: 300 },
  { id: 2, amount: 500 },
  { id: 3, amount: 1000 },
  { id: 4, amount: 5000 },
  { id: 5, amount: 1000 },
  { id:6, amount: 15000 },
  { id: 7, amount: 20000 },
  { id: 8, amount: 25000 },
];

export const PokerGames = [
  {
    image: "../assets/images/Aura/auraLobby.webp",
    title: "Lobby",
    gameCode: "98789",
    legacy: "",
  },

  {
    image: "../assets/images/poker/1.webp",
    title: "7 up & 7 down",
    gameCode: "98789",
    legacy: "98789_2",
  },
  {
    image: "../assets/images/poker/2.webp",
    title: "Live Teenpati",
    gameCode: "56767",
    legacy: "56767_2",
  },
  {
    image: "../assets/images/poker/3.webp",
    title: "Teenpatti T20",
    gameCode: "56768",
    legacy: "56768_2",
  },
  {
    image: "../assets/images/poker/4.webp",
    title: "Dragon Tiger",
    gameCode: "98790",
    legacy: "98790_2",
  },
  {
    image: "../assets/images/poker/5.webp",
    title: "Hi Low",
    gameCode: "56968",
    legacy: "56968_2",
  },
  {
    image: "../assets/images/poker/6.webp",
    title: "Worli Matka",
    gameCode: "92037",
    legacy: "92037_2",
  },
  {
    image: "../assets/images/poker/7.webp",
    title: "Poker",
    gameCode: "67564",
    legacy: "67564_2",
  },
  {
    image: "../assets/images/poker/8.webp",
    title: "32 Cards Casino",
    gameCode: "56967",
    legacy: "56967_2",
  },
  {
    image: "../assets/images/poker/9.webp",
    title: "Andhar Bahar",
    gameCode: "87564",
    legacy: "87564_2",
  },
  {
    image: "../assets/images/poker/10.webp",
    title: "Amar Akbar Anthony",
    gameCode: "98791",
    legacy: "98791_2",
  },
  {
    image: "../assets/images/poker/11.webp",
    title: "Race 20-20",
    gameCode: "90100",
    legacy: "90100_2",
  },
  {
    image: "../assets/images/poker/12.webp",
    title: "Bollywood Casino",
    gameCode: "67570",
    legacy: "67570_2",
  },
  {
    image: "../assets/images/poker/13.webp",
    title: "Six Player Poker",
    gameCode: "67565",
    legacy: "67565_2",
  },
  {
    image: "../assets/images/poker/14.webp",
    title: "Roulette",
    gameCode: "98788",
    legacy: "98788_2",
  },
  {
    image: "../assets/images/poker/15.webp",
    title: "Sic-bo",
    gameCode: "98566",
    legacy: "98566_2",
  },
  {
    image: "../assets/images/poker/16.webp",
    title: "Mufflis Teenpati",
    gameCode: "67601",
    legacy: "67601_3",
  },
  {
    image: "../assets/images/poker/17.webp",
    title: "Trio",
    gameCode: "67610",
    legacy: "67610_2",
  },
  {
    image: "../assets/images/poker/18.webp",
    title: "Baccarat",
    gameCode: "92038",
    legacy: "92038_2",
  },
  {
    image: "../assets/images/poker/19.webp",
    title: "Casino Meter",
    gameCode: "67575",
    legacy: "67575_2",
  },
  {
    image: "../assets/images/poker/20.webp",
    title: "Poker 20-20",
    gameCode: "67567",
    legacy: "67567_2",
  },
  {
    image: "../assets/images/poker/21.webp",
    title: "2 Card Teenpati",
    gameCode: "67660",
    legacy: "67660_2",
  },
  {
    image: "../assets/images/poker/22.webp",
    title: "Queen Race",
    gameCode: "67620",
    legacy: "67620_2",
  },
  {
    image: "../assets/images/poker/23.webp",
    title: "Teenpati Test",
    gameCode: "67630",
    legacy: "67630_2",
  },
  {
    image: "../assets/images/poker/24.webp",
    title: "Casino War",
    gameCode: "67580",
    legacy: "67580_2",
  },
  {
    image: "../assets/images/poker/25.webp",
    title: "The Trap",
    gameCode: "67680",
    legacy: "67680_2",
  },
  {
    image: "../assets/images/poker/26.webp",
    title: "Teenpati Open",
    gameCode: "67640",
    legacy: "67640_2",
  },
  {
    image: "../assets/images/poker/27.webp",
    title: "29 Card Baccarat",
    gameCode: "67690",
    legacy: "67690_2",
  },
  {
    image: "../assets/images/poker/28.webp",
    title: "Race to 17",
    gameCode: "67710",
    legacy: "67710_2",
  },
  {
    image: "../assets/images/poker/29.webp",
    title: "Roulette (Virtual)",
    gameCode: "98792",
    legacy: "98792_3",
  },
  {
    image: "../assets/images/poker/30.webp",
    title: "Dragon Tiger (Virtual)",
    gameCode: "98794",
    legacy: "98794_3",
  },
  {
    image: "../assets/images/poker/31.webp",
    title: "Amar Akbar Anthony (Virtual)",
    gameCode: "98795",
    legacy: "98795_3",
  },
  {
    image: "../assets/images/poker/32.webp",
    title: "Six Player Poker (Virtual)",
    gameCode: "67566",
    legacy: "67566_3",
  },
  {
    image: "../assets/images/poker/33.webp",
    title: "Poker (Virtual)",
    gameCode: "67563",
    legacy: "67563_3",
  },
  {
    image: "../assets/images/poker/34.webp",
    title: "32 Card Casino (Virtual)",
    gameCode: "56966",
    legacy: "56966_3",
  },
  {
    image: "../assets/images/poker/35.webp",
    title: "Teenpatti One-Day (Virtual)",
    gameCode: "56766",
    legacy: "56766_3",
  },
  {
    image: "../assets/images/poker/36.webp",
    title: "Andhar Bahar (Virtual)",
    gameCode: "87565",
    legacy: "87565_3",
  },
  {
    image: "../assets/images/poker/37.webp",
    title: "Teenpatti T20 (Virtual)",
    gameCode: "56769",
    legacy: "56769_3",
  },
  {
    image: "../assets/images/poker/38.webp",
    title: "Hi Low (Virtual)",
    gameCode: "56969",
    legacy: "56969_3",
  },
  {
    image: "../assets/images/poker/39.webp",
    title: "Matka (Virtual)",
    gameCode: "92036",
    legacy: "92036_3",
  },
  {
    image: "../assets/images/poker/40.webp",
    title: "7 Up & Down (Virtual)",
    gameCode: "98793",
    legacy: "98793_3",
  },
];

export const AviatorGames = [
  {
    image: "../assets/images/poker/avaitrix.jpg",
    gameCode: "9999",
    legacy: "67722-1_8",
    title: "AviatorX",
  },
  {
    image: "../assets/images/poker/aviator1.webp",
    gameCode: "9999",
    legacy: "67722-2_8",
    title: "Aviator",
  },
];
export const gamesLiveArray = [
  {
    img: "../assets/images/game-shows/GAME-SHOW-1.webp",
    name: "XXXtreme Lightning roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-205",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-2.webp",
    name: "Cash or Crash",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-175",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-3.webp",
    name: "Funky Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-183",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-4.webp",
    name: "Dead or Alive Saloon",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-176",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-5.webp",
    name: "Crazy Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-6.webp",
    name: "Lightning Dice",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-011",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-7.webp",
    name: "Lightning Roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-040",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-8.webp",
    name: "Lightning Fever",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-133",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-9.webp",
    name: "Extra Chilli",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-168",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-10.webp",
    name: "Imperial Quest",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-169",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-11.webp",
    name: "Monopoly Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-007",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-12.webp",
    name: "Monopoly Big Baller",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-018",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-13.webp",
    name: "Crazy Coin Flip",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-014",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-14.webp",
    name: "Mega Ball",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-010",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-15.webp",
    name: "Deal or Nodeal",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-025",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-246.png",
    name: "Gonzo's Treasure Map",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-246",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-075.webp",
    name: "Auto Mega Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-075",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-16.webp",
    name: "Wounderland",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-011",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-17.webp",
    name: "Shweet Bonanza Candy Land",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-044",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-18.webp",
    name: "Spin a Win Wild live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-015",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-19.webp",
    name: "Spin and Win Wild B X2",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-088",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-20.webp",
    name: "The Greatest Cards Show",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-086",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-21.webp",
    name: "Mega Wheel",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-038",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-22.webp",
    name: "Mega Fire Blaze Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-028",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-23.webp",
    name: "Mega Roulette 500x",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-039",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-24.webp",
    name: "Mega Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-061",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-25.webp",
    name: "Quantum Auto Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-026",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-26.webp",
    name: "Power Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-055",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-27.webp",
    name: "Sicbo Delux",
    gameType: "LIVE",
    platForm: "PT",
    casinoType: "PT-LIVE-003",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-28.webp",
    name: "Mega Sicbo",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-025",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-29.webp",
    name: "Bet on Dragon Tiger",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-030",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-30.webp",
    name: "Hi-lo Club",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-014",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-077.webp",
    name: "Lucky 6 Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-077",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-32.webp",
    name: "Italian Cashback Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-031",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-076.webp",
    name: "Lucky 6 Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-076",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },

  // {
  //   img: "../assets/images/game-shows/GAME-SHOW-31.webp",
  //   name: "Quantum BlackJack Plus",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-029",
  //   cateogry: "PT",
  //   type: "GAME-SHOWS",
  // },

  {
    img: "../assets/images/Baccarat/BACCARAT-1.webp",
    name: "Golden Wealth Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-179",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-2.webp",
    name: "Baccarat no commission",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-038",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-3.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-159",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-4.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-134",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-5.webp",
    name: "Lightning Baccarat First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-255",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-6.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-210",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-245.png",
    name: "Prosperity Tree Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-245",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-244.png",
    name: "First Person Prosperity Tree Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-244",
    cateogry: "EVO",
    type: "BACCARAT",
  },

  {
    img: "../assets/images/Baccarat/BACCARAT-7.webp",
    name: "Baccarat Classic",
    table: "1",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-8.webp",
    name: "Baccarat Classic",
    table: "2",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-9.webp",
    name: "Baccarat Classic",
    table: "3",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-10.webp",
    name: "Baccarat Classic",
    table: "4",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-11.webp",
    name: "Baccarat Classic",
    table: "5",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-12.webp",
    name: "Baccarat Classic",
    table: "6",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/PT-LIVE-109.png",
    name: "Bet on Baccarat Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-109",
    cateogry: "PT",
    type: "BACCARAT",
  },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-13.webp",
  //   name: "Bet on Baccarat Live",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-005",
  //   cateogry: "PT",
  //   type: "BACCARAT",
  // },
  {
    img: "../assets/images/Baccarat/BACCARAT-14.webp",
    name: "Baccarat 1",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-001",
    cateogry: "PT",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-15.webp",
    name: "Baccarat 3",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-001",
    cateogry: "PT",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-16.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-010",
    cateogry: "PP",
    type: "BACCARAT",
  },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-17.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-18.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-19.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-20.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-21.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-22.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  {
    img: "../assets/images/Baccarat/BACCARAT-24.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-013",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-25.webp",
    name: "Super 8 Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-045",
    cateogry: "PP",
    type: "BACCARAT",
  },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-26.webp",
  //   name: "Speed Baccarat",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-002",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-27.webp",
  //   name: "Baccarat Insurance",
  //   platForm: "SEXY",
  //   gameType: "LIVE",
  //   casinoType: "MX-LIVE-002",
  //   cateogry: "SEXY",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-28.webp",
  //   name: "Baccarat Insurance",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-003",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // Baccarat End

  // Roulette Start
  {
    img: "../assets/images/Roulette/ROULETTE-1.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-2.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-080",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-3.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-078",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-4.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-079",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-5.webp",
    name: "Instant Roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-008",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-6.webp",
    name: "Roulette First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-013",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-7.webp",
    name: "Amercian Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-020",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  // {
  //   img: "../assets/images/Roulette/ROULETTE-8.webp",
  //   name: "Roulette Live",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-001",
  //   cateogry: "EVO",
  //   type: "ROULETTE",
  // },
  {
    img: "../assets/images/Roulette/ROULETTE-9.webp",
    name: "Roulette",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-009",
    cateogry: "SEXY",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-10.webp",
    name: "Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-029",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-11.webp",
    name: "Auto Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-026",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-12.webp",
    name: "Speed Auto Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-060",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/PT-LIVE-102.png",
    name: "Speed Roulette 2",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PP-LIVE-102",
    cateogry: "PP",
    type: "ROULETTE",
  },
  // {
  //   img: "../assets/images/Roulette/ROULETTE-13.webp",
  //   name: "Roulette",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-009",
  //   cateogry: "VENUS",
  //   type: "ROULETTE",
  // },
  {
    img: "../assets/images/Roulette/ROULETTE-14.webp",
    name: "Speed Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-027",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-15.webp",
    name: "Azure Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-028",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-16.webp",
    name: "American Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-021",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-17.webp",
    name: "Deutsches Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-022",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-18.webp",
    name: "Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-025",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-19.webp",
    name: "Football French Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-023",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-20.webp",
    name: "Football Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-024",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-21.webp",
    name: "Spread Bets Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-027",
    cateogry: "PT",
    type: "ROULETTE",
  },

  {
    img: "../assets/images/Dice/DICE-1.webp",
    name: "Bac Bo Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-172",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-2.webp",
    name: "Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-005",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/EVOLUTION-LIVE-259.png",
    name: "First Person Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-259",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/EVOLUTION-LIVE-260.png",
    name: "Instant Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-260",
    cateogry: "EVO",
    type: "DICE",
  },

  {
    img: "../assets/images/Dice/DICE-3.webp",
    name: "Craps Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-003",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-4.webp",
    name: "Experor Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-211",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-5.webp",
    name: "Extra Sic bo",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-016",
    cateogry: "SEXY",
    type: "DICE",
  },

  {
    img: "../assets/images/Dice/PP-LIVE-079.webp",
    name: "Sic bo",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-079",
    cateogry: "PP",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/PT-LIVE-099.png",
    name: "Speed SicBo",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-099",
    cateogry: "PT",
    type: "DICE",
  },
  // {
  //   img: "../assets/images/Dice/DICE-6.webp",
  //   name: "Red Blue Duel",
  //   platForm: "SEXYBCRT",
  //   gameType: "LIVE",
  //   casinoType: "MX-LIVE-010",
  //   cateogry: "SEXY",
  //   type: "DICE",
  // },
  // {
  //   img: "../assets/images/Dice/DICE-7.webp",
  //   name: "Sic Bo",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-007",
  //   cateogry: "VENUS",
  //   type: "DICE",
  // },

  // Tradiotional Start
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-1.webp",
    name: "Super Andhar Bahar",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-204",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-2.webp",
    name: "Fan Tan",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-178",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-4.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-004",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-3.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-077",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-5.webp",
    name: "Teen Patti Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-026",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-6.webp",
    name: "Dragon Tiger",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-7.webp",
    name: "Dragon Tiger",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-10.webp",
    name: "Dragon Tiger",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-002",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-13.webp",
    name: "Dragon Tiger",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
    cateogry: "PP",
    type: "TRADITIONAL",
  },

  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-15.webp",
  //   name: "Dragon Tiger",
  //   platForm: "VN",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-006",
  //   cateogry: "VENUS",
  //   type: "TRADITIONAL",
  // },
  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-16.webp",
  //   name: "Dragon Tiger",
  //   platForm: "VN",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-006",
  //   cateogry: "VENUS",
  //   type: "TRADITIONAL",
  // },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-9.webp",
    name: "Extra Teen Patti",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-012",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-11.webp",
    name: "Andhar Bahar Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-004",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-14.webp",
    name: "Andhar Bahar",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-043",
    cateogry: "PP",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-8.webp",
    name: "Teen Patti",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-011",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/DICE/MX-LIVE-017_SEXY.png",
    name: "xocdia",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-017",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-12.webp",
  //   name: "Teen Patti",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-026",
  //   cateogry: "PT",
  //   type: "TRADITIONAL",
  // },
  // Tradiotional End

  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-181.webp",
  //   name: "Lightning Black Jack",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-181",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-208.webp",
    name: "Lightning Black Jack First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-208",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-227.png",
    name: "Lightning Black Jack First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-227",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-009.webp",
    name: "Triple Card Poker",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-009",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-012.webp",
    name: "Infinite Black Jack",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-012",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-006.webp",
    name: "Alexandrite Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-007",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-040.webp",
    name: "One Blackjack",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-040",
    cateogry: "PP",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-041.webp",
    name: "ONE Blackjack 2 - Ruby",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-041",
    cateogry: "PP",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-008.webp",
    name: "Majority Rules Speed Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-008",
    cateogry: "PT",
    type: "GAME-CARD",
  },

  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-002.webp",
  //   name: "Black Jack A",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-002",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },
  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-015.webp",
  //   name: "Casino Hold'em",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-015",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },

  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-024.webp",
  //   name: "Caribbean Stud Poker",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-024",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },

  // {
  //   img: "../assets/images/CardGames/PT-LIVE-007.webp",
  //   name: "All bets Blackjack",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-007",
  //   cateogry: "PT",
  //   type: "GAME-CARD",
  // },

  {
    img: "../assets/images/CardGames/PT-LIVE-010.webp",
    name: "Unlimited Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-010",
    cateogry: "PT",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/PT-LIVE-016.webp",
    name: "3 Card Brag",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-016",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-017.webp",
    name: "Casino Hold AEM",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-017",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-019.webp",
    name: "Bet on Poker Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-019",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  // {
  //   img: "../assets/images/CardGames/PT-LIVE-009.webp",
  //   name: "Sette E Mezzo",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-009",
  //   cateogry: "PT",
  //   type: "GAME-CARD",
  // },
  // Table Start  (casino Done) -------

  {
    img: "../assets/images/Table/TABLE-2.webp",
    name: "Cash Rocket",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-041",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-3.webp",
    name: "Ludo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-060",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-066.webp",
    name: "Coin Pusher",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-066",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-4.webp",
    name: "7 Up 7 Down",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-028",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-5.webp",
    name: "sicbo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-015",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/TABLE-6.webp",
    name: "Coin Toss",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-036",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-7.webp",
    name: "Cards Hi Lo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-037",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/NETENT-TABLE-001.png",
    name: "Classic Blackjack",
    platForm: "NETENT",
    gameType: "TABLE",
    casinoType: "NETENT-TABLE-001",
    cateogry: "NETENT",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-9.webp",
    name: "Card Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-022",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-021.webp",
    name: "Number Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-021",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-032.webp",
    name: "Andar Bahar",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-032",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-12.webp",
    name: "Bonus Dice",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-043",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-13.webp",
    name: "32 Cards",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-039",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-040.webp",
    name: "Plinko",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-040",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-067.webp",
    name: "Cockfighting Arena",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-067",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-16.webp",
    name: "Colour Game",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-050",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-053.webp",
    name: "Marble Knockout",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-053",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-048.webp",
    name: "Horse Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-048",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-055.webp",
    name: "KM Virtual Greyhound Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-055",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-058.webp",
    name: "KM Virtual Treadmill Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-058",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-8.webp",
    name: "5 Card Poker",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-049",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/TABLE-15.webp",
    name: "keno",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-018",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-045.webp",
    name: "Monkey King Roulette",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-045",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-038.webp",
    name: "Blackjack",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-038",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-042.webp",
    name: "Minesweeper",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-042",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-035.webp",
    name: "Bola Golek",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-035",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-030.webp",
    name: "jhandimunda",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-030",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  // Table End KINGMAKER (casino Done) -------

  // Table Start JILI (casino Done) -------
  {
    img: "../assets/images/JILI/JILI-TABLE-021.webp",
    name: "Ludo Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-021",
    cateogry: "JILI",
    type: "TABLE",
  },

  {
    img: "../assets/images/JILI/JILI-TABLE-014.webp",
    name: "Baccarat",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-014",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-017.webp",
    name: "Sic Bo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-017",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-016.webp",
    name: "TeenPatti 20-20",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-016",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-018.webp",
    name: "Super Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-018",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-015.webp",
    name: "Fortune Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-015",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-011.webp",
    name: "7up7down",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-011",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-013.webp",
    name: "Callbreak Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-013",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-012.webp",
    name: "Dragon & Tiger",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-012",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-010.webp",
    name: "TeenPatti Joker",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-010",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-008.webp",
    name: "iRich Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-008",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-009.webp",
    name: "Callbreak",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-009",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-003.webp",
    name: "Andar Bahar",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-003",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-004.webp",
    name: "Rummy",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-004",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-005.webp",
    name: "NumberKing",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-005",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-027.webp",
    name: "Golden Land",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-027",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-006.webp",
    name: "Poker King",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-006",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-007.webp",
    name: "Big small",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-007",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-028.webp",
    name: "PAPPU",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-028",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-035.webp",
    name: "Limbo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-035",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-036.webp",
    name: "wheel",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-036",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-039.webp",
    name: "Color Prediction",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-039",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-032.webp",
    name: "HILO",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-032",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-030.webp",
    name: "Go Rush",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-030",
    cateogry: "JILI",
    type: "TABLE",
  },

  {
    img: "../assets/images/JILI/JILI-TABLE-002.webp",
    name: "AK47",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-002",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-047.png",
    name: "Crash Bonus",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-047",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-042.png",
    name: "Mini Flush",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-042",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-033.png",
    name: "Mines",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-033",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-031.png",
    name: "Tower",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-031",
    cateogry: "JILI",
    type: "TABLE",
  },

  // Table End JILI (casino Done) -------

  // Other

  {
    img: "../assets/images/Others/MX-LIVE-015_SEXY.webp",
    name: "Thai Fish Prawn Crab",
    casinoType: "MX-LIVE-015",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    cateogry: "SEXY",
    type: "OTHERS",
  },
  // {
  //   img: "../assets/images/Others/VN-LIVE-008.webp",
  //   name: "Thai Fish Prawn Crab",
  //   casinoType: "VN-LIVE-008",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   cateogry: "VENUS",
  //   type: "OTHERS",
  // },
  // Other
  // Slot Start PP (casino Done) -----
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-075.webp",
    name: "Mahjong Win",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-075",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-017.png",
    name: "Starburst™ XXXtreme",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-017",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-014.png",
    name: "Divine Fortune™ Megaways™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-014",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-076.webp",
    name: "Super Hoo Hey How",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-076",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-010.png",
    name: "Finn's Golden Tavern™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-010",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-018.png",
    name: "Twin Spin™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-018",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-006.png",
    name: "Parthenon: Quest for Immortality™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-006",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-177.webp",
    name: "101 Candies",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-177",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-178.webp",
    name: "Big Bang Boom",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-178",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-179.webp",
    name: "Crab Trap™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-179",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-180.webp",
    name: "Jack and the Beanstalk Remastered",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-180",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-181.webp",
    name: "Thrill to Grill™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-181",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-082.webp",
    name: "Football Rules",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PT-SLOT-082",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-003.webp",
    name: "777",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-003",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-004.webp",
    name: "Get Money",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-004",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-012.webp",
    name: "Over Dragon's Gate",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-012",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-002.webp",
    name: "Phoenix",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-002",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-011.webp",
    name: "Diamond Mogul",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-011",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-005.webp",
    name: "Rich Now",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-005",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-008.webp",
    name: "Dracula",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-008",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-020.webp",
    name: "Bust Treasury",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-020",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PP-SLOT-002.webp",
    name: "Gates of Olympus",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-002",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-082.webp",
    name: "Sweet Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-082",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-189.webp",
    name: "Starlight Princess",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-189",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-183.webp",
    name: "5 Lions Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-183",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-080.webp",
    name: "5 Lions Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-080",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-049.webp",
    name: "Great Rhino Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-049",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-069.webp",
    name: "Fire Strike",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-069",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-067.webp",
    name: "Sweet Bonanza Xmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-067",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-015.webp",
    name: "Bonanza Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-015",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-176.webp",
    name: "Buffalo King Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-176",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-181.webp",
    name: "The Magic Cauldron - Enchanted Brew",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-181",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-046.webp",
    name: "Fruit Party",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-046",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-187.webp",
    name: "Pyramid Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-187",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-204.webp",
    name: "Candy Village",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-204",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-126.webp",
    name: "3 Kingdoms - Battle of Red Cliffs",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-126",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-104.webp",
    name: "5 Lions",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-104",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-072.webp",
    name: "John Hunter and the Tomb of the Scarab Queen",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-072",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-107.webp",
    name: "Joker's Jewels",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-107",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-025.webp",
    name: "Gems Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-025",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-210.webp",
    name: "Santa's Wonderland",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-210",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-233.webp",
    name: "Wild West Gold Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-233",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-234.webp",
    name: "Spirit of Adventure",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-234",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-235.webp",
    name: "Fire Strike 2",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-235",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-236.webp",
    name: "Barn Festival",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-236",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-237.webp",
    name: "Chicken Chase",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-237",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-238.webp",
    name: "Goblin Heist Powernudge",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-238",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-239.webp",
    name: "Eye of Cleopatra",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-239",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-240.webp",
    name: "The Great Stick-Up",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-240",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-296.webp",
    name: "Aztec Blaze",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-296",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-297.webp",
    name: "Starlight Christmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-297",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-298.webp",
    name: "Towering Fortunes",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-298",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-299.webp",
    name: "Firebird Spirit",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-299",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-300.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-300",
    cateogry: "PP",
    type: "SLOT",
  },
  // Slot End PP (casino Done) -----

  // Slot Start KINGMAKER (casino Done) -----
  {
    img: "../assets/images/slot/KM-SLOT-001.webp",
    name: "Sugar Blast",
    platForm: "KINGMAKER",
    gameType: "SLOT",
    casinoType: "KM-SLOT-001",
    cateogry: "KINGMAKER",
    type: "SLOT",
  },
  // Slot End KINGMAKER (casino Done) -----

  // Slot Start DRAGOONSOFT (casino Done) -----

  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-001.webp",
    name: "Coin Cat",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-001",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-015.webp",
    name: "Egypt Oracle",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-015",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-018.webp",
    name: "Rich Lion",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-018",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-017.webp",
    name: "Candy Dynasty",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-017",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-007.webp",
    name: "Doggy Wealth",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-007",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-016.webp",
    name: "Caishen Coming",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-016",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-006.webp",
    name: "Chef Lady",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-006",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-009.webp",
    name: "Maya King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-009",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-010.webp",
    name: "Pirate King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-010",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-013.webp",
    name: "Great Lion",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-013",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-014.webp",
    name: "Ultra Treasure",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-014",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-019.webp",
    name: "Monkey King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-019",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  // Slot End DRAGOONSOFT (casino Done) -----

  // Slot Start SPADE (casino Done) -----
  // {
  //   img: "../assets/images/slot/SG-SLOT-106.webp",
  //   name: "Fiery Sevens Exclusive",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-106",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-074.webp",
  //   name: "Mega7",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-074",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-116.webp",
  //   name: "Legacy Of Kong Maxways",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-116",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-069.webp",
  //   name: "888",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-069",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-112.webp",
  //   name: "Fruits Mania",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-112",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-076.webp",
  //   name: "DoubleFlame",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-076",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-110.webp",
  //   name: "Space Conquest",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-110",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-081.webp",
  //   name: "Triple Panda",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-081",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-077.webp",
  //   name: "Book of Myth",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-077",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-095.webp",
  //   name: "Poker Ways",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-095",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },

  // {
  //   img: "../assets/images/slot/SG-SLOT-086.webp",
  //   name: "Mayan Gems",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-086",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-075.webp",
  //   name: "JokersTreasure",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-075",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-030.webp",
  //   name: "5FortuneSA",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-030",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-103.webp",
  //   name: "Sexy Vegas",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-103",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-107.webp",
  //   name: "Lucky Koi Exclusive",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-107",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-082.webp",
  //   name: "Gold Panther",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-082",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-032.webp",
  //   name: "Golden Lotus SE",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-032",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-105.webp",
  //   name: "Royale House",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-105",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // Slot End SPADE (casino Done) -----

  // Slot Start PT (casino Done) -----

  {
    img: "../assets/images/slot/PT-SLOT-070.webp",
    name: "Heart of the Frontier",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-070",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-071.webp",
    name: "Thai Paradise",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-071",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-072.webp",
    name: "Legacy of the Wild",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-072",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-073.webp",
    name: "Archer",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-073",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-001.webp",
    name: "Triple Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-001",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-003.webp",
    name: "Buffalo Blitz",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-003",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-006.webp",
    name: "Great Blue",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-006",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-004.webp",
    name: "Captain Treasure",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-004",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-005.webp",
    name: "Long Long Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-005",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-009.webp",
    name: "Golden Tour",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-009",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-010.webp",
    name: "Funky Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-010",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-011.webp",
    name: "Highway Kings",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-011",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-014.webp",
    name: "Zhao Cai Tong Zi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-014",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-012.webp",
    name: "Zhao Cai Jin Bao",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-012",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-013.webp",
    name: "Jin Qian Wa",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-013",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-015.webp",
    name: "Ugga Bugga",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-015",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-016.webp",
    name: "Safari Heat",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-016",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-002.webp",
    name: "Arowanas Luck",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-002",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-019.webp",
    name: "Gem Queen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-019",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-023.webp",
    name: "Chaoji 888",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-023",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-008.webp",
    name: "Blue Wizard",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-008",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-018.webp",
    name: "Bonus Bears",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-018",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-017.webp",
    name: "Buffalo Blitz II",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-017",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-007.webp",
    name: "Sky Queen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-007",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-020.webp",
    name: "Feng Kuang Ma Jiang",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-020",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-021.webp",
    name: "Epic Ape",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-021",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-022.webp",
    name: "Ni Shu Shen Me",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-022",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-024.webp",
    name: "Vacation Station Deluxe",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-024",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-025.webp",
    name: "Eternal Lady",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-025",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-026.webp",
    name: "Golden Macaque",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-026",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-027.webp",
    name: "Fei Long Zai Tian",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-027",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-028.webp",
    name: "Yun Cong Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-028",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-029.webp",
    name: "Jinfu Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-029",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-030.webp",
    name: "Hot Gems",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-030",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-031.webp",
    name: "Lie Yan Zuan Shi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-031",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-032.webp",
    name: "Dolphin Reef",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-032",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-033.webp",
    name: "Jinns Moon",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-033",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-034.webp",
    name: "Tiger, Turtle, Dragon, Phoenix",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-034",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-035.webp",
    name: "Heart of the Jungle",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-035",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-036.webp",
    name: "Heavenly Ruler",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-036",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-037.webp",
    name: "Ox Riches",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-037",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-038.webp",
    name: "Ice Cave",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-038",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-039.webp",
    name: "Queen of Wands",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-039",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-040.webp",
    name: "Si Xiang",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-040",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-041.webp",
    name: "Panther Moon",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-041",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-043.webp",
    name: "Asian Fantasy",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-043",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-044.webp",
    name: "Fortune Lions",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-044",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-045.webp",
    name: "Hot KTV",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-045",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-046.webp",
    name: "Fortune Day",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-046",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-047.webp",
    name: "Ri Ri Jin Cai",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-047",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-048.webp",
    name: "Sun WuKong",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-048",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-049.webp",
    name: "Ji Xiang 8",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-049",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-050.webp",
    name: "Jade Emperor Yu Huang Da Di",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-050",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-051.webp",
    name: "Halloween Fortune 2",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-051",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-060.webp",
    name: "Chinese Kitchen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-060",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-061.webp",
    name: "Geisha Story",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-061",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-062.webp",
    name: "Fortunes of the Fox",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-062",
    cateogry: "PT",
    type: "SLOT",
  },
  // Slot End PT (casino Done) -----

  // Slot Start JDB (casino Done) -----
  {
    img: "../assets/images/slot/JDB-SLOT-108.webp",
    name: "Moneybags Man 2",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-108",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-106.webp",
    name: "FortuneNeko",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-106",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-107.webp",
    name: "Trump Card",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-107",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-105.webp",
    name: "BookOfMystery",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-105",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-001.webp",
    name: "Burglar",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-001",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-003.webp",
    name: "Chef Doeuvre",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-003",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-004.webp",
    name: "Lucky Miner",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-004",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-005.webp",
    name: "Candy Land",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-005",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-006.webp",
    name: "Crazy Scientist",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-006",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-007.webp",
    name: "Super Dumpling",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-007",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-008.webp",
    name: "Cash Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-008",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-009.webp",
    name: "Wild Beauty",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-009",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-010.webp",
    name: "Flirting Scholar Tang",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-010",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-011.webp",
    name: "Winning Mask",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-011",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-012.webp",
    name: "Wu kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-012",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-013.webp",
    name: "Llama Adventure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-013",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-014.webp",
    name: "Formosa Bear",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-014",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-015.webp",
    name: "Lucky Qilin",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-015",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-016.webp",
    name: "New Year",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-016",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-017.webp",
    name: "Four Treasures",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-017",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-018.webp",
    name: "Open Sesame",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-018",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-019.webp",
    name: "Banana Saga",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-019",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-020.webp",
    name: "Olympian Temple",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-020",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-021.webp",
    name: "Dancing Pa Pa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-021",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-022.webp",
    name: "Dragon King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-022",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-023.webp",
    name: "Magic Show",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-023",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-024.webp",
    name: "Cock Fight",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-024",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-025.webp",
    name: "Dragon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-025",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-026.webp",
    name: "Rooster In Love",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-026",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-027.webp",
    name: "Monkey King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-027",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-028.webp",
    name: "Moonlight Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-028",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-029.webp",
    name: "Mahjong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-029",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-030.webp",
    name: "Guan Gong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-030",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-031.webp",
    name: "Billionaire",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-031",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-032.webp",
    name: "Dragon Warrior",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-032",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-033.webp",
    name: "Kingsman",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-033",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-034.webp",
    name: "Curvy Magician",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-034",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-035.webp",
    name: "RollingIn Money",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-035",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-036.webp",
    name: "Chef Panda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-036",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-045.webp",
    name: "Lucky Dragons",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-045",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-046.webp",
    name: "Lucky Lion",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-046",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-047.webp",
    name: "Coffeeholics",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-047",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-048.webp",
    name: "Napoleon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-048",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-049.webp",
    name: "Lucky Phoenix",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-049",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-050.webp",
    name: "WinningMask II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-050",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-051.webp",
    name: "Open Sesame II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-051",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-052.webp",
    name: "Flirting Scholar Tang II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-052",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-053.webp",
    name: "Fortune Horse",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-053",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-054.webp",
    name: "Xi Yang Yang",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-054",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-055.webp",
    name: "Panda Panda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-055",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-056.webp",
    name: "Zelda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-056",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-057.webp",
    name: "Mr Bao",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-057",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-058.webp",
    name: "One Punch Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-058",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-059.webp",
    name: "Guardians of The Galaxy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-059",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-060.webp",
    name: "Street Fighter",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-060",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-061.webp",
    name: "Star Wars",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-061",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-062.webp",
    name: "War of Beauty",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-062",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-063.webp",
    name: "Daji",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-063",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-064.webp",
    name: "Poker King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-064",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-065.webp",
    name: "Lucky Racing",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-065",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-066.webp",
    name: "Birds Party",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-066",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-067.webp",
    name: "Go Lai Fu",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-067",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-068.webp",
    name: "Gems Gems",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-068",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-069.webp",
    name: "Dragons World",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-069",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-070.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-070",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-071.webp",
    name: "Egypt Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-071",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-072.webp",
    name: "Fortune Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-072",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-073.webp",
    name: "Pirate Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-073",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-074.webp",
    name: "Mjoinir",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-074",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-075.webp",
    name: "Treasure Bowl",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-075",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-076.webp",
    name: "Funky King Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-076",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-077.webp",
    name: "Super Niubi Deluxe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-077",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-078.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-078",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-079.webp",
    name: "Lucky Fuwa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-079",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-080.webp",
    name: "Inca Empire",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-080",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-081.webp",
    name: "Ninja Rush",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-081",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-082.webp",
    name: "Sun Archer",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-082",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-083.webp",
    name: "Mystery of Nine Tales",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-083",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-084.webp",
    name: "Golden Disco",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-084",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-085.webp",
    name: "Miner Babe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-085",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-086.webp",
    name: "Moneybags Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-086",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-087.webp",
    name: "Double Wilds",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-087",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-088.webp",
    name: "Pop Pop Fruity",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-088",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-089.webp",
    name: "Spindrift",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-089",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-090.webp",
    name: "Spindrift 2",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-090",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-091.webp",
    name: "Jungle Jungle",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-091",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-092.webp",
    name: "Dragons Gate",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-092",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-093.webp",
    name: "Lucky Diamond",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-093",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-094.webp",
    name: "Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-094",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-095.webp",
    name: "NinjaX",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-095",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-096.webp",
    name: "Rex Brothers",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-096",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-097.webp",
    name: "Wonder Elephant",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-097",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-098.webp",
    name: "MarvelousIV",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-098",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-099.webp",
    name: "Maya Gold Crazy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-099",
    cateogry: "JDB",
    type: "SLOT",
  },
  // Slot End JDB (casino Done) -----

  // Slot Start FC (casino Done) -----

  // {
  //   img: "../assets/images/slot/FC-SLOT-025.webp",
  //   name: "SUGAR BANG BANG",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-025",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-021.webp",
  //   name: "Lucky Fortunes",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-021",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-020.webp",
  //   name: "Robin Hood",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-020",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-019.webp",
  //   name: "GLORY OF ROME",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-019",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-001.webp",
  //   name: "GOLDEN PANTHER",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-001",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-002.webp",
  //   name: "THREE LITTLE PIGS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-002",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-003.webp",
  //   name: "HOT POT PARTY",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-003",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-004.webp",
  //   name: "NIGHT MARKET",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-004",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-005.webp",
  //   name: "PONG PONG HU",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-005",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-006.webp",
  //   name: "PANDA DRAGON BOAT",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-006",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-007.webp",
  //   name: "CHINESE NEW YEAR",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-007",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-008.webp",
  //   name: "FORTUNE KOI",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-008",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-009.webp",
  //   name: "HAPPY DUO BAO",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-009",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-010.webp",
  //   name: "	DA LE MEN",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-010",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-011.webp",
  //   name: "ANIMAL RACING",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-011",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-012.webp",
  //   name: "LUXURY GOLDEN PANTHER",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-012",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-013.webp",
  //   name: "MAGIC BEANS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-013",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-014.webp",
  //   name: "COWBOYS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-014",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-015.webp",
  //   name: "WAR OF THE UNIVERSE",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-015",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-016.webp",
  //   name: "TREASURE CRUISE",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-016",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // Slot End FC (casino Done) -----

  // Slot Start JILI (casino Done) -----
  {
    img: "../assets/images/slot/JILI-SLOT-063.webp",
    name: "World Cup",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-063",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-056.webp",
    name: "Happy Taxi",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-056",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-057.webp",
    name: "Gold Rush",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-057",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-054.webp",
    name: "Crazy Pusher",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-054",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-053.webp",
    name: "Book of Gold",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-053",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-071.webp",
    name: "Neko Fortune",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-071",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-051.webp",
    name: "Mega Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-051",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-052.webp",
    name: "Thor X",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-052",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-002.webp",
    name: "Hot Chilli",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-002",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-003.webp",
    name: "Chin Shi Huang",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-003",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-004.webp",
    name: "War Of Dragons",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-004",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-005.webp",
    name: "Fortune Tree",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-005",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-006.webp",
    name: "Lucky Ball",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-006",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-007.webp",
    name: "Hyper Burst",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-007",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-008.webp",
    name: "Shanghai Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-008",
    cateogry: "JILI",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/JILI-SLOT-009.webp",
    name: "Fa Fa Fa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-009",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-010.webp",
    name: "God Of Martial",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-010",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-012.webp",
    name: "Hawaii Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-012",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-013.webp",
    name: "SevenSevenSeven",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-013",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-014.webp",
    name: "Crazy777",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-014",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-015.webp",
    name: "Bubble Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-015",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-016.webp",
    name: "Bao boon chin",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-016",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-017.webp",
    name: "Crazy FaFaFa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-017",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-018.webp",
    name: "XiYangYang",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-018",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-019.webp",
    name: "FortunePig",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-019",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-020.webp",
    name: "Candy Baby",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-020",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-021.webp",
    name: "Diamond Party",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-021",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-022.webp",
    name: "Fengshen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-022",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-023.webp",
    name: "Golden Bank",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-023",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-024.webp",
    name: "Lucky Goldbricks",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-024",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-025.webp",
    name: "Dragon Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-025",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-026.webp",
    name: "Charge Buffalo",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-026",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-027.webp",
    name: "Super Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-027",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-028.webp",
    name: "Jungle King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-028",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-029.webp",
    name: "Money Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-029",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-030.webp",
    name: "Golden Queen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-030",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-031.webp",
    name: "Boxing King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-031",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-032.webp",
    name: "Matka India",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-032",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-037.webp",
    name: "Lucky Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-037",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-038.webp",
    name: "Pharaoh Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-038",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-039.webp",
    name: "Secret Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-039",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-040.webp",
    name: "RomaX",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-040",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-041.webp",
    name: "Super Rich",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-041",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-042.webp",
    name: "Golden Empire",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-042",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-043.webp",
    name: "Fortune Gems",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-043",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-044.webp",
    name: "Party Night",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-044",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-045.webp",
    name: "Crazy Hunter",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-045",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-046.webp",
    name: "Magic Lamp",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-046",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-047.webp",
    name: "TWINWINS",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-047",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-048.webp",
    name: "Agent Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-048",
    cateogry: "JILI",
    type: "SLOT",
  },
  // Slot End JILI (casino Done) -----

  //slot spade start
  {
    img: "../assets/images/slot/SG-SLOT-106.png",
    name: "Fiery Sevens Exclusive",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-106",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-074.png",
    name: "Mega7",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-074",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-116.png",
    name: "Legacy Of Kong Maxways",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-116",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-069.webp",
    name: "888",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-069",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-112.webp",
    name: "Fruits Mania",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-112",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-076.png",
    name: "DoubleFlame",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-076",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-110.webp",
    name: "Space Conquest",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-110",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-081.png",
    name: "TriplePanda",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-081",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-077.png",
    name: "BookofMyth",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-077",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-095.png",
    name: "Poker Ways",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-095",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-086.png",
    name: "Mayan Gems",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-086",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-075.png",
    name: "JokersTreasure",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-075",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-030.png",
    name: "5FortuneSA",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-030",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-103.webp",
    name: "Sexy Vegas",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-103",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-107.png",
    name: "Lucky Koi Exclusive",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-107",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-082.png",
    name: "GoldPanther",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-082",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-032.webp",
    name: "GoldenLotusSE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-032",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-105.png",
    name: "Royale House",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-105",
    cateogry: "SPADE",
    type: "SLOT",
  },

  //slot spade end
  {
    img: "../assets/images/slot/FC-SLOT-025.png",
    name: "SUGAR BANG BANG",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-025",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-023.png",
    name: "GRAND BLUE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-023",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-024.png",
    name: "TREASURE RAIDERS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-024",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-021.png",
    name: "Lucky Fortunes",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-021",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-020.png",
    name: "Robin Hood",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-020",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-019.png",
    name: "GLORY OF ROME",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-019",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-001.png",
    name: "GOLDEN PANTHER",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-001",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-002.png",
    name: "THREE LITTLE PIGS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-002",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-003.png",
    name: "HOT POT PARTY",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-003",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-004.png",
    name: "NIGHT MARKET",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-004",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-005.png",
    name: "PONG PONG HU",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-005",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-006.png",
    name: "PANDA DRAGON BOAT",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-006",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-007.png",
    name: "CHINESE NEW YEAR",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-007",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-008.png",
    name: "FORTUNE KOI",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-008",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-009.png",
    name: "HAPPY DUO BAO",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-009",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-010.png",
    name: "DA LE MEN",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-010",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-011.png",
    name: "ANIMAL RACING",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-011",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-012.png",
    name: "LUXURY GOLDEN PANTHER",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-012",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-013.png",
    name: "MAGIC BEANS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-013",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-014.png",
    name: "COWBOYS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-014",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-015.png",
    name: "WAR OF THE UNIVERSE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-015",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-016.png",
    name: "TREASURE CRUISE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-016",
    cateogry: "FC",
    type: "SLOT",
  },

  //slot FC start

  //slot FC end

  // Fishing Start JILI (casino Done) -----
  {
    img: "../assets/images/Fishing/JDB-FISH-008.webp",
    name: "Royal Fishing",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-001",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-010.webp",
    name: "Fishing YiLuFa",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-002",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-011.webp",
    name: "Dragon Master",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-004",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-013.webp",
    name: "Fishing Legend",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-005",
    cateogry: "JDB",
    type: "FISHING",
  },

  // Fishing Start SPADE (casino Done) -----
  {
    img: "../assets/images/Fishing/SG-FISH-001.webp",
    name: "Fishing God",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-001",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-002.webp",
    name: "Fishing War",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-002",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-003.webp",
    name: "Alien Hunter",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-003",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-004.webp",
    name: "Zombie Party",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-004",
    cateogry: "SPADE",
    type: "FISHING",
  },
  // Fishing End SPADE (casino Done) -----
  {
    img: "../assets/images/Fishing/JILI-FISH-008.webp",
    name: "Boom Legend",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-008",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-001.webp",
    name: "Royal Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-001",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-004.webp",
    name: "Dinosaur Tycoon",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-004",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-002.webp",
    name: "Bombing Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-002",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-007.webp",
    name: "Mega Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-007",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-006.webp",
    name: "Dragon Fortune",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-006",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-005.webp",
    name: "Happy Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-005",
    cateogry: "JILI",
    type: "FISHING",
  },

  {
    img: "../assets/images/Fishing/JILI-FISH-003.webp",
    name: "Jackpot Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-003",
    cateogry: "JILI",
    type: "FISHING",
  },

  // Fishing End JILI (casino Done) -----
  // EGAME SPRIBE
  {
    img: "../assets/images/Egame/22001.png",
    name: "Aviator",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-001",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22002.png",
    name: "Dice",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-003",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22008.png",
    name: "Mini Roulette",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-008",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22005.png",
    name: "Mines",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-005",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22006.png",
    name: "Spribe Hilo",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-006",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22004.png",
    name: "Plinko",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-004",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22003.png",
    name: "Goal",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-002",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22009.png",
    name: "Hotline",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-009",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22007.png",
    name: "Keno",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-007",
    cateogry: "SPRIBE",
    type: "EGAME",
  },

  // Egame Start JDB (casino Done) -----
  {
    img: "../assets/images/Egame/JDB-EGAME-002.webp",
    name: "Lucky Color Game",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-009",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-006.webp",
    name: "King Of Football",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-010",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-001.webp",
    name: "Crazy King Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-011",
    cateogry: "JDB",
    type: "EGAME",
  },

  {
    img: "../assets/images/Egame/JDB-EGAME-007.webp",
    name: "Super Super Fruit",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-012",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-011.webp",
    name: "Jogo Do Bicho",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-013",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-010.webp",
    name: "Beer Tycoon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-014",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-005.webp",
    name: "CAISHEN PARTY",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-015",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-003.webp",
    name: "Birds and Animals",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-016",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-009.webp",
    name: "Classic Mario",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-017",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-004.webp",
    name: "Happy New Year",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-018",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-008.webp",
    name: "Huaguoshan Legends",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JJDB-SLOT-019",
    cateogry: "JDB",
    type: "EGAME",
  },

  // Egame End JDB (casino Done) -----

  //PP EGAME START

  {
    img: "../assets/images/Egame/PP-EGAME-004.webp",
    name: "Fantastic League",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-001",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-002.webp",
    name: "Penalty Shootout",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-002",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-005.webp",
    name: "Greyhound Racing",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-005",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-003.webp",
    name: "Horse Racing",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-003",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-006.webp",
    name: "Force 1",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-006",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-007.webp",
    name: "Darts",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-007",
    cateogry: "PP",
    type: "EGAME",
  },

  //PP EGAME END

  {
    img: "../assets/images/game-shows/HOTROAD-LIVE-001.png",
    name: "BACCARAT",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/HOTROAD-LIVE-007.png",
    name: " HOT Baccarat",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "GAME-SHOWS",
  },

  // {
  //   img: "../assets/images/game-shows/HOTROAD-LIVE-004.png",
  //   name: "HOT ROULETTE",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-004",
  //   cateogry: "HOTROAD",
  //   type: "GAME-SHOWS",
  // },

  {
    img: "../assets/images/game-shows/HOTROAD-LIVE-006.png",
    name: "HOT SICBO",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "GAME-SHOWS",
  },
  // AWC Start -----

  // AWC BG Start -----
  {
    img: "../assets/images/Awc/BG-LIVE-001.webp",
    name: "Live Casino BG",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-001",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-002.webp",
    name: "Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-002",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-003.webp",
    name: "Roulette Awc",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-003",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-004.webp",
    name: "Sicbo Awc",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-004",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-005.webp",
    name: "Dragon tiger",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-005",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-006.webp",
    name: "Speed Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-006",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-007.webp",
    name: "Mi Card Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-007",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-008.webp",
    name: "Full Color Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-008",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-009.webp",
    name: "BullBull",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-009",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-010.webp",
    name: "Win Three Cards",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-010",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-011.webp",
    name: "RedBlack",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-011",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-012.webp",
    name: "SpeedSicBo",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-012",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-013.webp",
    name: "SeDie",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-013",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-014.webp",
    name: "CasinoWar",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-014",
    cateogry: "BG",
    type: "AWC",
  },
  // AWC BG END -----
// AWC BTG START -----
  {
    img: "../assets/images/Awc/BTG-SLOT-001.webp",
    name: "Apollo Pays",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-001",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-002.webp",
    name: "Castle of Terror",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-002",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-003.webp",
    name: "Rasputin Megaways",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-003",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-004.webp",
    name: "Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-004",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-005.webp",
    name: "Extra Chilli",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-005",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-006.webp",
    name: "White Rabbit",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-006",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-007.webp",
    name: "Millionaire",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-007",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-008.webp",
    name: "Golden Catch",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-008",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-009.webp",
    name: "Danger High Voltage",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-009",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-010.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-010",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-011.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-011",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-012.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-012",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-013.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-013",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-014.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-014",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-015.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-015",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-016.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-016",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-017.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-017",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-018.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-018",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-019.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-019",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-020.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-020",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-021.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-021",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-022.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-022",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-023.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-023",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-024.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-024",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-025.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-025",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-026.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-026",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-027.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-027",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-028.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-028",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-029.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-029",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-030.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-030",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-031.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-031",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-032.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-032",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-033.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-033",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-034.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-034",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-035.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-035",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-036.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-036",
    cateogry: "BTG",
    type: "AWC",
  },
  
  {
    img: "../assets/images/Awc/BTG-SLOT-038.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-038",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-039.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-039",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-040.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-040",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-041.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-041",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-042.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-042",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-043.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-043",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-044.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-044",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-045.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-045",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-046.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-046",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-047.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-047",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-048.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-048",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-049.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-049",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-050.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-050",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-051.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-051",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-052.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-052",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-053.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-053",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-054.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-054",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-055.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-055",
    cateogry: "BTG",
    type: "AWC",
  },

  // AWC BTG END -----
  // AWC E1SPORT START -----
  {
    img: "../assets/images/Awc/E1-ESPORTS-001.webp",
    name: "E1Sports",
    platForm: "E1SPORT",
    gameType: "ESPORTS",
    casinoType: "E1-ESPORTS-001",
    cateogry: "E1SPORT",
    type: "AWC",
  },
    // AWC E1SPORT END -----

      // AWC NLC START -----
  {
    img: "../assets/images/Awc/NLC-SLOT-001.webp",
    name: "Das x Boot",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-001",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-002.webp",
    name: "Mental",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-002",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-003.webp",
    name: "Punk Toilet",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-003",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-004.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-004",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-005.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-005",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-006.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-006",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-007.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-007",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-008.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-008",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-009.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-009",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-010.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-010",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-011.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-011",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-012.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-012",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-013.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-013",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-014.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-014",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-015.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-015",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-016.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-016",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-017.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-017",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-018.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-018",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-019.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-019",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-020.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-020",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-021.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-021",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-022.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-022",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-023.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-023",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-024.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-024",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-025.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-025",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-026.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-026",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-027.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-027",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-028.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-028",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-029.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-029",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-030.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-030",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-031.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-031",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-032.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-032",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-033.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-033",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-034.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-034",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-035.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-035",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-036.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-036",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-037.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-037",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-038.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-038",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-039.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-039",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-040.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-040",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-041.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-041",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-042.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-042",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-043.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-043",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-044.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-044",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-045.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-045",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-046.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-046",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-047.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-047",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-048.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-048",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-049.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-049",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-050.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-050",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-051.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-051",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-052.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-052",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-053.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-053",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-054.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-054",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-055.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-055",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-056.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-056",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-057.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-057",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-058.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-058",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-059.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-059",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-060.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-060",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-061.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-061",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-062.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-062",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-063.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-063",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-064.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-064",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-065.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-065",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-066.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-066",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-067.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-067",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-068.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-068",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-069.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-069",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-070.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-070",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-071.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-071",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-072.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-072",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-073.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-073",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-074.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-074",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-075.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-075",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-076.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-076",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-077.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-077",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-078.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-078",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-079.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-079",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-080.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-080",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-081.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-081",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-082.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-082",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-083.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-083",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-084.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-084",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-085.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-085",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-086.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-086",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-087.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-087",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-088.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-088",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-089.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-089",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-090.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-090",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-091.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-091",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-092.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-092",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-093.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-093",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-094.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-094",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-095.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-095",
    cateogry: "NLC",
    type: "AWC",
  },
        // AWC NLC END -----
 // AWC RT START -----
  {
    img: "../assets/images/Awc/RT-SLOT-001.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-001",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-002.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-002",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-003.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-003",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-004.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-004",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-005.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-005",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-006.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-006",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-007.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-007",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-008.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-008",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-009.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-009",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-010.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-010",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-011.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-011",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-012.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-012",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-013.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-013",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-014.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-014",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-015.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-015",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-016.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-016",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-017.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-017",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-018.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-018",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-019.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-019",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-020.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-020",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-021.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-021",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-022.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-022",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-023.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-023",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-024.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-024",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-025.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-025",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-026.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-026",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-027.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-027",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-028.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-028",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-029.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-029",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-030.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-030",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-031.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-031",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-032.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-032",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-033.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-033",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-034.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-034",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-035.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-035",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-036.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-036",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-037.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-037",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-038.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-038",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-039.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-039",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-040.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-040",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-041.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-041",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-042.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-042",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-0433.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-043",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-044.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-044",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-045.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-045",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-046.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-046",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-047.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-047",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-048.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-048",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-049.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-049",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-050.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-050",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-051.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-051",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-052.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-052",
    cateogry: "RT",
    type: "AWC",
  },
   // AWC RT END -----
 // AWC SV388 START -----

  {
    img: "../assets/images/Awc/SV-LIVE-001.webp",
    name: "SV388",
    platForm: "SV388",
    gameType: "LIVE",
    casinoType: "SV-LIVE-001",
    cateogry: "SV388",
    type: "AWC",
  },
   // AWC SV388 END -----
    // AWC YESBINGO START -----
  {
    img: "../assets/images/Awc/YesBingo-BINGO-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-002.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-002",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-003.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-003",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-004.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-004",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-005.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-005",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-006.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-006",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-007.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-007",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-008.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-008",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-009.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-009",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-010.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-010",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-011.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-011",
    cateogry: "YesBingo",
    type: "AWC",
  },
 
  {
    img: "../assets/images/Awc/YesBingo-FH-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "FH",
    casinoType: "YesBingo-FH-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-002.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-002",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-003.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-003",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-004.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-004",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-005.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-005",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-006.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-006",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-007.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-007",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-008.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-008",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-009.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-009",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-010.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-010",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-011.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-011",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-012.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-012",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-013.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-013",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-014.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-014",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-015.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-015",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-016.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-016",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-017.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-017",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-018.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-018",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-019.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-019",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-020.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-020",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-021.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-021",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-022.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-022",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-023.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-023",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-024.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-024",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-025.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-025",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-026.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-026",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-027.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-027",
    cateogry: "YesBingo",
    type: "AWC",
  },
  
  {
    img: "../assets/images/Awc/YesBingo-SLOT-029.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-029",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-030.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-030",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-031.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-031",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-EGAME-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "EGAME",
    casinoType: "YesBingo-EGAME-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-EGAME-002.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "EGAME",
    casinoType: "YesBingo-EGAME-002",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-EGAME-003.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "EGAME",
    casinoType: "YesBingo-EGAME-003",
    cateogry: "YesBingo",
    type: "AWC",
  },
      // AWC YESBINGO END -----
          // AWC YL START -----
  {
    img: "../assets/images/Awc/YL-EGAME-001.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-001",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-002.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-002",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-003.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-003",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-004.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-004",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-005.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-005",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-006.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-006",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-007.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-007",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-008.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-008",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-009.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-009",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-010.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-010",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-011.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-011",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-012.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-012",
    cateogry: "YL",
    type: "AWC",
  },
       // AWC YL END -----
  // AWC End -----
];