import React, { useContext, useEffect, useRef, useState } from "react";
import badge from "../../img/badge.png";
import { Col, Row } from "react-bootstrap";
import { isEmpty, isNumber } from "lodash";
import BetSlipContext from "../../context/BetSlipContext";
import useScroll from "../../component/Scroll";
import { useNavigate } from "react-router-dom";
const Fancy = ({
  title,
  data,
  user,
  detail,
  profileData,
  setLimit,
  scrollHeight,
  position,setRightTab
}) => {
  const { setShowBetDialog, setBetSlipObject, betSlipObject, showBetDialog } =
    useContext(BetSlipContext);
  const naviagte = useNavigate();
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [localLimit, setLocalLimit] = useState({});
  useEffect(() => {
    let obj = profileData?.matchSetting?.find(
      (res) =>
        res?.type == "fancy" &&
        (profileData?.arrayCheck == "details"
          ? true
          : res?.sportType == detail?.gameType)
    );
    if (!isEmpty(obj)) {
      setLocalLimit(obj);
    } else {
      setLocalLimit({
        maxAmount: 1000,
        maxProfit: 10000,
        minAmount: 1,
        oddsLimit: 10,
      });
    }
  }, []);

  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setRightTab('betslip')
        setScrollId(index);
        setShowBetDialog(true);
        setLimit(localLimit);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: team,
          betType: type,
          playType: "fancy",
          object: {
            ...item,
            ...odds,
            teamName: team,
            eventId: detail?.eventId,
            marketId: detail?.marketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  const mergeAndCalculate = (betSlipObject, apiData) => {
    const betRun = betSlipObject.odds;
    let profitAmount;
    let loseAmount;

    if (betSlipObject.betType === "Yes") {
      profitAmount = parseFloat(
        ((betSlipObject.bidPrice * betSlipObject.object.pt) / 100).toFixed(2)
      );
      loseAmount = -parseFloat(betSlipObject.bidPrice);
    } else if (betSlipObject.betType === "No") {
      profitAmount = parseFloat(betSlipObject.bidPrice);
      loseAmount = -parseFloat(
        ((betSlipObject.bidPrice * betSlipObject.object.pt) / 100).toFixed(2)
      );
    }

    let mergedBetRunPosition = apiData.betRunPosition.map((position) => ({
      ...position,
      position:
        position.position !== null && position.position !== undefined
          ? position.position
          : 0,
    }));

    const minBetRun = Math.min(
      ...mergedBetRunPosition.map((item) => item.betRun)
    );
    const maxBetRun = Math.max(
      ...mergedBetRunPosition.map((item) => item.betRun)
    );

    if (betRun > maxBetRun) {
      for (let i = maxBetRun + 1; i <= betRun; i++) {
        mergedBetRunPosition.push({ betRun: i, position: 0 });
      }
    } else if (betRun < minBetRun) {
      for (let i = betRun + 1; i <= minBetRun - 1; i++) {
        mergedBetRunPosition.push({ betRun: i, position: 0 });
      }
    }

    mergedBetRunPosition.forEach((item) => {
      if (betSlipObject.betType === "Yes") {
        if (item.betRun >= betRun) {
          item.position += profitAmount;
        } else {
          item.position += loseAmount;
        }
      } else if (betSlipObject.betType === "No") {
        if (item.betRun < betRun) {
          item.position += profitAmount;
        } else {
          item.position += loseAmount;
        }
      }
    });

    mergedBetRunPosition.sort((a, b) => a.betRun - b.betRun);

    const maxLossAmount = Math.min(
      ...mergedBetRunPosition.map((item) => item.position)
    );

    return maxLossAmount;
  };

  return (
    <div className="leagueBox mb-3">
      <div className="title">
        <Row>
          <Col xs={7}>
            <img src={badge} style={{ maxWidth: "20px" }} /> {title}
          </Col>

          <Col xs={5} className="text-dark text-center">
            <Row>
              <Col className="text-center px-0">NO</Col>
              <Col className="text-center px-0">Yes</Col>
            </Row>
          </Col>
        </Row>
      </div>

      {data?.map((item, index) => {
        if (
          item?.odds?.ms == 4 ||
          item?.odds?.rt?.length == 0 ||
          item?.odds?.ms == 2 ||
          isEmpty(item?.odds?.rt)
        ) {
          return null;
        } else {
          let oldPosition = !isEmpty(
            position?.find((res) => {
              return res?.selectionId == item?.selectionId;
            })
          )
            ? position?.find((res) => {
                return res?.selectionId == item?.selectionId;
              })?.position
            : "";

          let newPosition = "";
          if (oldPosition && position) {
            let oldPositionData =
              position?.find((res) => {
                return res?.selectionId == item?.selectionId;
              }) || [];

            if (betSlipObject?.teamName === item?.name && showBetDialog) {
              betSlipObject.betType = betSlipObject?.object?.ib ? "Yes" : "No";

              newPosition =
                oldPositionData && oldPositionData?.betRunPosition
                  ? mergeAndCalculate(betSlipObject, oldPositionData)
                  : 0;
            }
          } else {
            if (betSlipObject?.teamName === item?.name && showBetDialog) {
              if (betSlipObject?.object?.ib) {
                if (Math.abs(item?.position) > 0) {
                  let backLoss =
                    Number(betSlipObject?.bidPrice) + Number(item?.position);
                  newPosition = backLoss;
                } else {
                  let backLoss = Number(betSlipObject?.bidPrice);
                  newPosition = backLoss;
                }
              } else {
                if (Math.abs(item?.position) > 0) {
                  let backProfit =
                    (betSlipObject?.bidPrice * betSlipObject?.object?.pt) / 100;
                  newPosition = Number(backProfit) + Number(item.position);
                } else {
                  let backProfit =
                    (betSlipObject?.bidPrice * betSlipObject?.object?.pt) / 100;
                  newPosition = backProfit;
                }
              }
            }
          }

          let condition =
            betSlipObject?.teamName === item?.name &&
            Math.abs(newPosition) >= 0;

          return (
            <div
              // style={{
              //   scrollMarginTop: scrollHeight,
              // }}
              // ref={index == scrollId ? myRef : null}
              className="laegueRow"
            >
              <Row>
                <Col xs={7} className="">
                  {item?.name}{" "}
                  {(isNumber(oldPosition) || condition) && (
                    <span className={`d-block py-1 ${"team-red-score"}`}>
                      {isNumber(oldPosition)
                        ? `( ${Math?.abs(item?.position).toFixed(2)} )`
                        : ""}
                      {betSlipObject?.teamName === item?.name &&
                        Math.abs(newPosition) >= 0 && (
                          <span>
                            {" "}
                            {">"} {`( ${Math.abs(newPosition).toFixed(2)} )`}
                          </span>
                        )}
                    </span>
                  )}
                </Col>
                <Col xs={5} className="text-dark text-center  px-0">
                  <div className="d-flex position-relative">
                    <div
                      title={
                        item?.odds?.rt?.length > 0 ? item?.odds?.rt[0]?.rt : ""
                      }
                      id={"lay_odds_fancy_" + index}
                      onClick={() =>
                        handelChange(
                          item?.odds?.rt[0],
                          "No",
                          item?.name,
                          item,
                          index
                        )
                      }
                      className={`bluBoxs d-inline-block ${
                        Number(
                          document.getElementById("lay_odds_fancy_" + index)
                            ?.title
                        ) !== item?.odds?.rt[0]?.rt
                          ? "spark-lay"
                          : ""
                      }  ${
                        betSlipObject?.teamName == item?.name &&
                        betSlipObject?.odds == item?.odds?.rt[0]?.rt &&
                        showBetDialog &&
                        betSlipObject?.betType == "No"
                          ? "lay-active"
                          : ""
                      }`}
                    >
                      {item?.odds?.rt[0]?.rt}
                      <span>{item?.odds?.rt[0]?.pt}</span>
                    </div>
                    <div
                      title={
                        item?.odds?.rt?.length > 0 ? item?.odds?.rt[1]?.rt : ""
                      }
                      id={"back_odds_fancy_" + index}
                      onClick={() =>
                        handelChange(
                          item?.odds?.rt[1],
                          "Yes",
                          item?.name,
                          item,
                          index
                        )
                      }
                      className={`redBoxs  d-inline-block  ${
                        Number(
                          document.getElementById("back_odds_fancy_" + index)
                            ?.title
                        ) !== item?.odds?.rt[1]?.rt
                          ? "spark-back"
                          : ""
                      }  ${
                        betSlipObject?.teamName == item?.name &&
                        betSlipObject?.odds == item?.odds?.rt[1]?.rt &&
                        showBetDialog &&
                        betSlipObject?.betType == "Yes"
                          ? "back-active"
                          : ""
                      }`}
                    >
                      {item?.odds?.rt[1]?.rt}
                      <span> {item?.odds?.rt[1]?.pt}</span>
                    </div>
                    {(item?.odds?.ms == 9 ||
                      item?.odds?.ms == 2 ||
                      item?.odds?.ms == 3 ||
                      item?.odds?.ms == 4) && (
                      <div class="overlay-match">
                        {item?.odds?.ms == 9
                          ? "Ball Running"
                          : item?.odds?.ms == 2
                          ? "In Active"
                          : item?.odds?.ms == 3
                          ? "Suspended"
                          : item?.odds?.ms == 4
                          ? "Closed"
                          : ""}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          );
        }
      })}
    </div>
  );
};

export default Fancy;
