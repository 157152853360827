import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import logo from "../img/logo.png";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import loginImg from "../img/login-icon.png";
import live from "../img/live.png";
import sportBet from "../img/sport-betting.png";
import Casino from "../img/casino.png";
import LuckyDrops from "../img/l-drops.png";
import LiveDrops from "../img/l-drops.png";
import Promotion from "../img/promotion.png";
import coin from "../img/coin.png";
import userImg from "../img/user.png";

import Auth from "../img/auth.png";
import BetsMenu from "../img/bets.png";
import BettingPL from "../img/betting-pl.png";
import CPassword from "../img/c-password.png";
import CrossImg from "../img/cross-img.png";
import Fav from "../img/favorite.png";
import News from "../img/news.png";
import Rules from "../img/rules.png";
import StakeSetting from "../img/stake-setting.png";
import Transfer from "../img/transfer.png";

import Aos from "aos";
import "aos/dist/aos.css";
import props from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
function Header() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  let {
    user,
    showSidebar,
    handleShowSidebar,
    userCoins,
    amounutRefresh,
    refreshAmountLoader,
    message,
    logoutUser,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <header data-aos="fade-down" className="sticky-top header">
      <Navbar expand="lg" className="justify-content-between bg-body-light">
        <Container fluid>
          <Navbar.Brand className="ms-2" href="/">
            <img src={logo} style={{ maxWidth: "180px" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link href="/">
                <img src={live} />
                Home
              </Nav.Link>{" "}
              <Nav.Link href="/casino/poker">
                <img src={Casino} />
                Indian Poker
              </Nav.Link>{" "}
              <Nav.Link href="/casino/Live">
                <img src={Casino} />
                Live
              </Nav.Link>
              <Nav.Link href="/casino/Table">
                <img src={Casino} />
                Table
              </Nav.Link>
              <Nav.Link href="/casino/Fishing">
                <img src={Casino} />
                Fishing
              </Nav.Link>
              {!isEmpty(user) && (
                <Nav.Link href="/bets-history">
                  <img src={sportBet} />
                  Betting History
                </Nav.Link>
              )}
              {!isEmpty(user) && (
                <>
                  <Nav.Link href="/current-bets">
                    <img src={LuckyDrops} />
                    Current Bets
                  </Nav.Link>
                  <Nav.Link href="/profit-and-loss">
                    <img src={LiveDrops} />
                    Profit & Loss
                  </Nav.Link>
                  <Nav.Link href="/customer-support">
                    <img src={Promotion} />
                    Customer Support
                  </Nav.Link>
                </>
              )}
            </Nav>
            {!isEmpty(user) ? (
              <div className="d-flex me-2">
                <span className="amounts me-2">
                  <img src={coin} /> ${user?.user?.totalCoins}
                </span>
                <Dropdown className="uDetails d-inline-block">
                  <Dropdown.Toggle id="dropdown-basic" align="end">
                    <span className="u-name text-start">
                      Welcome,
                      <br />
                      {user?.user?.username}
                    </span>
                    <span className="u-img">
                      <img src={userImg} />
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <div className="whiteBox mx-2 p-2 br-6">
                      <img src={userImg} className="uimage" />
                      <h2 className="uname"> {user?.user?.username}</h2>
                    </div>
                    <div className="values  mx-2 my-2">
                      <Row className="mx-0">
                        <Col className="px-2 py-2">
                          {user?.user?.totalCoins}{" "}
                          <span>Available Balance</span>
                        </Col>
                        <Col className="px-2 py-2">
                          {user?.user?.exposure} <span>Exposure</span>
                        </Col>
                      </Row>
                      {/* <Row className="mx-0">
                      <Col className="px-2 py-2 b-r">
                        500 <span>Winnings</span>
                      </Col>
                      <Col className="px-2 py-2 c-red">
                        -75 <span>Net Exposure</span>
                      </Col>
                    </Row> */}
                    </div>

                    <div className="whiteBox mx-2 p-2 br-6">
                      <ul className="u-menus">
                        <li>
                          <Link to="/current-bets">
                            <img src={LiveDrops} /> Current Bets
                          </Link>
                        </li>
                        <li>
                          <Link to="/profit-and-loss">
                            <img src={LiveDrops} /> Profit / Loss
                          </Link>
                        </li>
                        <li>
                          <Link to="/bets-history">
                            <img src={LiveDrops} /> Bet History
                          </Link>
                        </li>
                        <li>
                          <Link to="/deposit">
                            <img src={LiveDrops} /> Deposit
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/deposit-history">
                            <img src={LiveDrops} /> Deposit History
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/withdraw">
                            <img src={LiveDrops} /> Withdraw
                          </Link>
                        </li>
                        <li>
                          <Link to="/withdraw-history">
                            <img src={LiveDrops} /> Withdraw History
                          </Link>
                        </li>
                        {/* <li>
                  <Link to="/balance-overview">
                    <img src={LiveDrops} /> Balance Overview
                  </Link>
                </li>{" "} */}
                        <li>
                          <Link to="/account-statement">
                            <img src={LiveDrops} /> Account Statement
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="d-grid gap-2">
                      <Button
                        onClick={() => logoutUser()}
                        variant="primary"
                        className="btn-red mx-2"
                        size="sm"
                      >
                        Log Out
                      </Button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <div className="social-header">
                <Link to="/register" className="login">
                  <img src={loginImg} alt="" style={{ marginRight: "5px" }} />
                  Singup
                </Link>
                <Link to="/login" className="login">
                  <img src={loginImg} alt="" style={{ marginRight: "5px" }} />
                  Login
                </Link>
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="subNav">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/cricket">Cricket</Link>
          </li>
          <li>
            <Link to="/tennis">Tennis</Link>
          </li>
          <li>
            <Link to="/soccer">Soccer</Link>
          </li>
          <li>
            <Link to="/casino/poker">Casino</Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
