import logo from "./logo.svg";
import "./App.css";
import "./custom.css"
import { Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import LeaguePage from "./pages/league";
import MatchPage from "./pages/MatchDetail/match";
import CasinoPage from "./pages/casino";
import CasinoDetail from "./pages/casino-detail";
import ForgotPassword from "./pages/Forgot-password";
import UpdatePassword from "./pages/update-password";
import Home from "./pages/home";
import SprotsInner from "./pages/SprotsInner";
import Register from "./pages/Register";
import Deposit from "./pages/Deposit";
import WithDraw from "./pages/WithDraw";
import DepositHistory from "./pages/DepositHistory";
import WithdrawHistory from "./pages/WithdrawHistory";
import AccountStatement from "./pages/AccountStatement";
import Index from "./CustomerSupport.js/Index";
import BetsHistory from "./pages/BetDetails/BetsHistory";
import CurrentBets from "./pages/BetDetails/CurrentBets/CurrentBets";
import ProfitLoss from "./pages/BetDetails/ProfitLoss";
function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} exact />{" "}
      <Route path="/register" element={<Register />} exact />
      <Route path="/" element={<Home />} />
      <Route path="/:sportType" element={<Home />} />
      <Route path="/LeaguePage" element={<LeaguePage />} />
      <Route path="/match/:eventId/:marketId" element={<MatchPage />} />
      <Route path="/casino" element={<CasinoPage />} />
      <Route path="/casino/:type" element={<CasinoPage />} />
      <Route path="/deposit" element={<Deposit />} /> <Route path="/withdraw" element={<WithDraw />} />
      <Route path="/CasinoDetail" element={<CasinoDetail />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/UpdatePassword" element={<UpdatePassword />} />
      <Route path="/deposit-history" element={<DepositHistory />} />
      <Route path="/withdraw-history" element={<WithdrawHistory />} />
      <Route path="/account-statement" element={<AccountStatement />} />
      <Route path="/customer-support" element={<Index />} />
      <Route path="/bets-history" element={<BetsHistory />} />
      <Route path="/current-bets" element={<CurrentBets />} />
      <Route path="/profit-and-loss" element={<ProfitLoss />} />
    </Routes>
  );
}

export default App;
