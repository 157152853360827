import { render, screen } from "@testing-library/react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link } from "react-router-dom";

import Header from "../component/header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import IndFlag from "../img/flag-india.png";

import trophy from "../img/trophy.png";

import RightBanner from "../img/right-banner.png";
import RightBanner1 from "../img/right-banner-1.png";
import Dice from "../img/dise.png";
import C1 from "../img/c-01.png";
import C2 from "../img/c-2.png";
import C3 from "../img/c-3.png";
import C4 from "../img/item1.png";
import C5 from "../img/item2.png";
import C6 from "../img/item3.png";
import C7 from "../img/item4.png";
import C8 from "../img/item5.png";
import C9 from "../img/item6.png";
import Banner from "../img/casino-banner.png";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useState } from "react";
import { AviatorGames, gamesLiveArray, PokerGames } from "../Utils/constants";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "./Layout";
function CasinoPage() {
  const params = useParams();
  let { launchEGTCasino, user, launchCasino, dolaunchCasinoNew } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [key, setKey] = useState("poker");
  useEffect(() => {
    if (!isEmpty(params?.type)) {
      setKey(params?.type);
    } else {
      setKey("poker");
    }
  }, [params]);

  const [array, setArray] = useState([]);
  useEffect(() => {
    setArray({
      live:
        gamesLiveArray?.filter((res) => {
          return ["ROULETTE", "GAME-SHOWS"].includes(res?.type);
        }) || [],
      awc:
        gamesLiveArray?.filter((res) => {
          return res?.type == "AWC";
        }) || [],
      dice:
        gamesLiveArray?.filter((res) => {
          return res?.type == "DICE";
        }) || [],
      table:
        gamesLiveArray?.filter((res) => {
          return res?.type == "TABLE";
        }) || [],
      slot:
        gamesLiveArray?.filter((res) => {
          return res?.type == "SLOT";
        }) || [],
      fishing:
        gamesLiveArray?.filter((res) => {
          return res?.type == "FISHING";
        }) || [],
      egame:
        gamesLiveArray?.filter((res) => {
          return res?.type == "EGAME";
        }) || [],
    });
  }, []);

  const handel = (res) => {
    if (!isEmpty(user)) {
      if (!isEmpty(res?.gameid)) {
        launchEGTCasino({
          platForm: res?.platForm,
          gameType: res?.gameType,
          casinoType: res?.casinoType,
          gameid: res?.gameid,
        });
      } else {
        if (
          res?.platForm !== "" &&
          res?.gameType !== "" &&
          res?.casinoType !== ""
        ) {
          if (!isEmpty(res?.table)) {
            launchCasino({
              platForm: res?.platForm,
              gameType: res?.gameType,
              casinoType: res?.casinoType,
              table: res?.table,
            });
          } else {
            launchCasino({
              platForm: res?.platForm,
              gameType: res?.gameType,
              casinoType: res?.casinoType,
            });
          }
        }
      }
    } else {
      navigate("/login");
    }
  };
  const handelChange = (res, game, type) => {
    if (!isEmpty(user)) {
      dolaunchCasinoNew(
        game == "poker"
          ? {
              gameCode: res?.gameCode,
              legacy: res?.legacy,
              type: type,
            }
          : {
              gameCode: res?.gameCode,
              legacy: res?.legacy,
              type: type,
            }
      );
    } else {
      navigate("/login");
    }
  };
  return (
    <Layout>
      <Col md={9}>
        <div data-aos="zoom-in-up" className=" mb-2">
          <img src={Banner} style={{ maxWidth: "100%" }} />
        </div>

        <div data-aos="zoom-in-up" className="casinoTabs mb-3">
          <Tabs
            activeKey={key}
            onSelect={(e) => setKey(e)}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="poker" title="Indian Poker">
              <Row>
                {PokerGames?.map((res) => {
                  return (
                    <Col xs={2}>
                      <div
                        onClick={() => handelChange(res, "poker", "first")}
                        className="cBlock"
                      >
                        <img src={res?.image} />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Aviator" title="Aviator">
              <Row>
                {AviatorGames?.map((res) => {
                  return (
                    <Col xs={2}>
                      <div
                        onClick={() => handelChange(res, "", "first")}
                        className="cBlock"
                      >
                        <img src={res?.image} />
                        <h2>
                          <a href="/CasinoDetail">{res?.title}</a>
                        </h2>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Live" title="Live">
              <Row>
                {array?.live?.map((res) => {
                  return (
                    <Col xs={2}>
                      <div onClick={() => handel(res)} className="cBlock">
                        <img src={res?.img} />
                        <h2>
                          <a href="/CasinoDetail">{res?.name}</a>
                        </h2>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Table" title="Table">
              <Row>
                {array?.table?.map((res) => {
                  return (
                    <Col xs={2}>
                      <div onClick={() => handel(res)} className="cBlock">
                        <img src={res?.img} />
                        <h2>
                          <a href="/CasinoDetail">{res?.name}</a>
                        </h2>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Slot" title="Slot">
              <Row>
                {array?.slot?.map((res) => {
                  return (
                    <Col xs={2}>
                      <div onClick={() => handel(res)} className="cBlock">
                        <img src={res?.img} />
                        <h2>
                          <a href="/CasinoDetail">{res?.name}</a>
                        </h2>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Tab>{" "}
            <Tab eventKey="Dice" title="Dice">
              <Row>
                {array?.dice?.map((res) => {
                  return (
                    <Col xs={2}>
                      <div onClick={() => handel(res)} className="cBlock">
                        <img src={res?.img} />
                        <h2>
                          <a href="/CasinoDetail">{res?.name}</a>
                        </h2>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Fishing" title="Fishing">
              <Row>
                {array?.fishing?.map((res) => {
                  return (
                    <Col xs={2}>
                      <div onClick={() => handel(res)} className="cBlock">
                        <img src={res?.img} />
                        <h2>
                          <a href="/CasinoDetail">{res?.name}</a>
                        </h2>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Egame" title="Egame">
              <Row>
                {array?.egame?.map((res) => {
                  return (
                    <Col xs={2}>
                      <div onClick={() => handel(res)} className="cBlock">
                        <img src={res?.img} />
                        <h2>
                          <a href="/CasinoDetail">{res?.name}</a>
                        </h2>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="AWC" title="AWC">
              <Row>
                {array?.awc?.map((res) => {
                  return (
                    <Col xs={2}>
                      <div onClick={() => handel(res)} className="cBlock">
                        <img src={res?.img} />
                        <h2>
                          <a href="/CasinoDetail">{res?.name}</a>
                        </h2>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            {/* <Tab eventKey="crash" title="Crash">
              <Row>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C1} />
                    <h2>
                      <a href="/CasinoDetail">3 Clown Monty</a>
                    </h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C2} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C3} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C4} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C5} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C6} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C7} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C8} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C9} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C1} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C2} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C3} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C4} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C5} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C6} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C7} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C8} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C9} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="miner" title="Miner">
              <Row>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C1} />
                    <h2>
                      <a href="/CasinoDetail">3 Clown Monty</a>
                    </h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C2} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C3} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C4} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C5} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C6} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C7} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C8} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C9} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C1} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C2} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C3} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C4} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C5} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C6} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C7} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C8} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C9} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="hilo" title="Hilo">
              <Row>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C1} />
                    <h2>
                      <a href="/CasinoDetail">3 Clown Monty</a>
                    </h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C2} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C3} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C4} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C5} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C6} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C7} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C8} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C9} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C1} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C2} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C3} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C4} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C5} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C6} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C7} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C8} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div data-aos="zoom-in-up" className="cBlock">
                    <img src={C9} />
                    <h2>3 Clown Monty</h2>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="dice" title="Dice">
              Tab content for Contact
            </Tab> */}
          </Tabs>
        </div>
      </Col>
    </Layout>
  );
}

export default CasinoPage;
