import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import AuthContext from "../context/AuthContext";
import { compact, isEmpty, startCase } from "lodash";
import SidebarLayout from "../component/SidebarLayout";
const DepositHistory = () => {
  const { userCoins, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [listing, setListing] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });
  const handel = () => {
    setFilter({
      ...filter,
      page: filter?.page + 1,
    });
    getData(
      {
        ...filter,
        page: filter?.page + 1,
      },
      "add"
    );
  };
  const getData = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.depositHistory, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results]);
        } else {
          setListing(data?.results);
        }
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <SidebarLayout heading={"Deposit History"}>
      <div  className="p-3 pt-4 inner-sidebar-content d-flex flex-column ">
        <div className="balance-deposit-sec">
          <Table bordered className="rounded overflow-hidden bg-white">
            {listing?.length > 0
              ? listing?.map((res) => {
                  return (
                    <>
                      <thead>
                        <tr>
                          <th colSpan="4">
                            <strong className={`d-block`}>
                              TrxID {res.TransactionId}
                            </strong>
                            <p style={{ margin: "5px 0 0 0" }}>
                              {obj.dateFormat(res?.createdAt)}
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr>
                          <td width="50%">
                            Deposit Type
                            <strong className={`d-block`}>
                              {res.transactionType}
                            </strong>
                          </td>
                          <td width="50%">
                            Bank
                            <strong className={`d-block`}>
                              {res?.BankAccount || res.bank}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="50%">
                            Amount
                            <strong className={`d-block`}>{res.amount}</strong>
                          </td>
                          <td width="50%">
                            Status
                            <strong className={`d-block`}>
                              <span
                                className={
                                  res.status == "completed"
                                    ? "text-success"
                                    : res.status == "declined"
                                    ? "text-danger"
                                    : ""
                                }
                              >
                                {startCase(res.status)}
                              </span>
                            </strong>
                          </td>
                        </tr>
                        {!isEmpty(res?.remark) && (
                          <tr>
                            <td width="100%" colSpan={2}>
                              <strong className={`d-block`}>Remark</strong>
                              <p>{res.remark}</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </>
                  );
                })
              : "No Record Found"}
          </Table>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default DepositHistory;
