import React from "react";
import Row from "react-bootstrap/esm/Row";
import BatBlue from "../../img/bat-blue.png";
import trophy from "../../img/trophy.png";
import telecase from "../../img/telecast.png";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/esm/Col";
const ListSection = ({ data, heading, allOdds, type }) => {
  const navigate = useNavigate();

  const emptyOdds = () => {
    return (
      <div className="d-flex">
        <div className="bluBoxs">
          -<span>-</span>
        </div>
        <div className="redBoxs">
          -<span>-</span>
        </div>
      </div>
    );
  };
  return (
    <div className="leagueBox mb-2">
      <div className="title">
        <Row>
          <Col md={7}>
            {type == "in_play" && <span className="live">LIVE</span>}
            <img src={BatBlue} style={{ maxWidth: "20px" }} /> {heading}
          </Col>
          <Col md={5} className="text-dark text-center">
            <Row>
              <Col className="text-center" md={4}>
                1
              </Col>
              <Col className="text-center" md={4}>
                2
              </Col>
              <Col className="text-center" md={4}>
                3
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {data?.map((item) => {
        let Odds =
          allOdds?.length > 0
            ? allOdds?.find((res) => {
                return res?.mi == item?.marketId;
              })
            : {};
        let odds_lay =
          Odds?.rt?.length > 0 ? Odds?.rt?.filter((todd) => !todd?.ib) : [];
        let odds_back =
          Odds?.rt?.length > 0 ? Odds?.rt?.filter((todd) => todd?.ib) : [];
        return (
          <div
            onClick={() =>
              navigate(`/match/${item?.eventId}/${item?.marketId}`)
            }
            className="laegueRow active pointer-event"
          >
            <Row>
              <Col md={7}>
                <Row className="mb-1">
                  <Col md={5}>{item?.eventName?.split(" v ")[0]}</Col>
                  {/* <Col md={7}>175/7</Col> */}
                </Row>
                <Row>
                  <Col md={5}>{item?.eventName?.split(" v ")[1]}</Col>
                  {/* <Col md={7}>176/9 (15.5)</Col> */}
                </Row>
              </Col>
              <Col md={5} className="text-dark text-center">
                <Row className="mx-0">
                  <Col className="text-center px-0" md={4}>
                    {odds_back?.length >= 2 ? (
                      <div className="d-flex">
                        <div className="bluBoxs">
                          {odds_back[1]?.rt}
                          <span>{odds_back[1]?.bv}</span>
                        </div>
                        <div className="redBoxs">
                          {odds_lay[1]?.rt}
                          <span>{odds_lay[1]?.bv}</span>
                        </div>
                      </div>
                    ) : (
                      emptyOdds()
                    )}
                  </Col>
                  <Col className="text-center px-0" md={4}>
                    {odds_back?.length >= 1 ? (
                      <div className="d-flex">
                        <div className="bluBoxs">
                          {odds_back[0]?.rt}
                          <span>{odds_back[0]?.bv}</span>
                        </div>
                        <div className="redBoxs">
                          {odds_lay[0]?.rt}
                          <span>{odds_lay[0]?.bv}</span>
                        </div>
                      </div>
                    ) : (
                      emptyOdds()
                    )}
                  </Col>
                  <Col className="text-center px-0" md={4}>
                    {" "}
                    {odds_back?.length >= 3 ? (
                      <div className="d-flex">
                        <div className="bluBoxs">
                          {odds_lay[2]?.rt}
                          <span>{odds_lay[2]?.bv}</span>
                        </div>
                        <div className="redBoxs">
                          {odds_lay[2]?.rt}
                          <span>{odds_lay[2]?.bv}</span>
                        </div>
                      </div>
                    ) : (
                      emptyOdds()
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default ListSection;
