import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import objConstant from "../Utils/constants";
import AuthContext from "../context/AuthContext";
import { compact, isEmpty, startCase } from "lodash";
import { IoIosArrowForward } from "react-icons/io";
import SidebarLayout from "../component/SidebarLayout";
const AccountStatement = () => {
  const { userCoins, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [listing, setListing] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });
  const handel = () => {
    setFilter({
      ...filter,
      page: filter?.page + 1,
    });
    getData(
      {
        ...filter,
        page: filter?.page + 1,
      },
      "add"
    );
  };
  const getData = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.accountStatement, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results?.data]);
        } else {
          setListing(data?.results?.data);
        }
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      getData();
    }
  }, [user]);

  const getDataCasino = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.casionStatement, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results?.data]);
        } else {
          setListing(data?.results?.data);
        }
      }
    }
  };
  return (
    <SidebarLayout heading={"Account Statement"}>
      <Container className="p-0">
        <div className="inner-sidebar-content d-flex flex-column px-2">
          <div className="balance-sec mb-2">
            <h5>Your Balances</h5>
            <h6 className="fs-4">
              {" "}
              <span>INR</span> {userCoins?.balance?.toFixed(2)}
            </h6>
          </div>
          {/* <Button
          style={{ background: "#18b0c8", color: "black", border: "none" }}
          className="mb-3"
          onClick={() => {
            getDataCasino({ page: 1, limit: 10 }, "");
            setFilter({ page: 1, limit: 10 });
          }}
        >
          International Casino Transaction
        </Button> */}
          {listing?.length > 0
            ? listing?.map((res) => {
                return (
                  <div className="balance-deposit-sec">
                    <Table
                      bordered
                      className="rounded overflow-hidden bg-white"
                    >
                      <thead>
                        <tr>
                          <th colSpan="4">
                            {obj.dateFormat(
                              res?.createdAt || res?.timeInserted
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {!isEmpty(res?.casinoBetId) ? (
                            <>
                              <td width="50%">
                                Casino Bet Id
                                <strong className="d-block">
                                  {res?.casinoBetId}
                                </strong>
                              </td>
                              <td width="50%">
                                Client
                                <strong className="d-block">
                                  {res?.clientName}
                                </strong>
                              </td>
                            </>
                          ) : (
                            <>
                              <td width="50%">
                                {res?.forBet == 1
                                  ? res?.amount >= 0
                                    ? "Credits"
                                    : "Debits"
                                  : res?.transactionType}
                                <strong
                                  className={`d-block ${
                                    res?.transactionType === "credit" ||
                                    res?.amount >= 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {res?.transactionType === "credit"
                                    ? res?.amount?.toFixed(2)
                                    : `(${res?.amount?.toFixed(2)})`}
                                </strong>
                              </td>
                              <td width="50%">
                                Balance{" "}
                                <strong className="d-block">
                                  {res?.newBalance[0]?.toFixed(2)}
                                </strong>
                              </td>
                            </>
                          )}
                        </tr>
                        {!isEmpty(res?.remark) && (
                          <tr>
                            <td
                              style={{ fontSize: "14px" }}
                              width="50%"
                              colSpan="4"
                            >
                              {"Remark"}
                              <strong className="d-block">
                                {res?.remark || ""}
                              </strong>

                              {/*  <strong className="d-block">
                              {res?.forBet == 1 &&
                                (!isEmpty(res?.casinoBetId)
                                  ? `${res?.platform} - ${res?.gameInfo}`
                                  : compact([
                                      startCase(res?.gameType),
                                    res?.gameType == 'casino' ? '' : res?.betFaireType,
                                      res?.gameName,
                                      res?.eventId,
                                      res?.runnerName,
                                    ]).join(" / "))}
                            </strong> */}
                            </td>
                          </tr>
                        )}
                        {/* {!isEmpty(res?.casinoBetId) ? (
                        <tr>
                          <td style={{ fontSize: "14px" }} width="50%">
                            Stake
                            <strong className="d-block">
                              {res?.betAmount}
                            </strong>
                          </td>
                          <td style={{ fontSize: "14px" }} width="50%">
                            Profit / Loss
                            <strong className="d-block">
                              {res?.realCutAmount}
                            </strong>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td width="50%" colSpan="4">
                            {res?.createdByData?.username}{" "}
                            <span className="master-pass"></span>
                            {!isEmpty(res?.userData?.firstName) && (
                              <span className="master-pass">
                                {res?.userData?.firstName ||
                                  res?.userData?.lastName}
                              </span>
                            )}
                          </td>
                        </tr>
                      )} */}
                      </tbody>
                    </Table>
                  </div>
                );
              })
            : "No Record Found"}
          {data?.hasNextPage && (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Button
                onClick={handel}
                style={{ background: "black", border: "none" }}
              >
                View More <IoIosArrowForward />
              </Button>
            </div>
          )}
        </div>
      </Container>
    </SidebarLayout>
  );
};

export default AccountStatement;
