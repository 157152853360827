import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Cross from "../../img/cross.png";
import Rupee from "../../img/rupee.png";
import { isEmpty, isNumber } from "lodash";
import BetSlipContext from "../../context/BetSlipContext";
import { useNavigate, useParams } from "react-router-dom";
import useScroll from "../../component/Scroll";
import BackLay from "./BackLay";
const Selection = ({
  name,
  bookmaker,
  runner,
  backOdds,
  layOdds,
  oldBack,
  oldLay,
  profileData,
  marketName,
  detail,
  ms,
  position,
  typeGame,
  setShowBetDialog,
  setLimit,
  setBetSlipObject,
  user,
  scrollHeight,
  marketId,setRightTab
}) => {
  const parmas = useParams();
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [localLimit, setLocalLimit] = useState({});
  const { showBetDialog,betSlipObject } = useContext(BetSlipContext);
  const naviagte = useNavigate();
  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setRightTab('betslip')
        setScrollId(index);
        setShowBetDialog(true);
        setLimit(localLimit);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: team,
          betType: type,
          playType: typeGame,
          newMarketId: marketId,
          object: {
            ...item,
            ...odds,
            teamName: team,
            eventId: detail?.eventId,
            marketId: marketId,
            newMarketId: marketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  useEffect(() => {
    let result = profileData?.matchSetting?.find((res) => {
      return res?.type == marketName;
    });
    if (!isEmpty(result)) {
      let obj = result;
      if (!isEmpty(obj)) {
        setLocalLimit(obj);
      }
    } else {
      let obj = profileData?.matchSetting?.find(
        (res) =>
          res?.type == (typeGame == "bookmaker" ? "bookmaker" : "betFaire") &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == detail?.gameType)
      );

      if (!isEmpty(obj)) {
        if (marketName == "Match Odds") {
          if (!isEmpty(detail?.betLimitSetting)) {
            setLocalLimit(
              !isEmpty(obj)
                ? {
                    ...obj,
                    minAmount: detail?.betLimitSetting?.minBet,
                    maxAmount: detail?.betLimitSetting?.maxBet,
                  }
                : {}
            );
          } else {
            setLocalLimit(!isEmpty(obj) ? obj : {});
          }
        } else {
          setLocalLimit(obj);
        }
      } else {
        setLocalLimit({
          maxAmount: 500,
          maxProfit: 10000,
          minAmount: 1,
          oddsLimit: 20,
        });
      }
    }
  }, [marketName]);
console.log(betSlipObject,'======')
  return (
    <div data-aos="zoom-in-up" className="leagueBox mb-3">
      {/* {!isEmpty(bookmaker) && <div className="PremiumBox"> {bookmaker}</div>} */}
      <div className="title">
        <Row>
          <Col md={3}>
            {" "}
            <img src={Cross} style={{ maxWidth: "20px" }} />
            {name}
          </Col>
          <Col className="text-center" md={4}>
            <span className="Cashout">
              <img src={Rupee} style={{ width: "13px" }} /> Cashout
            </span>
          </Col>
          <Col md={5} className="text-dark text-center">
            <Row>
              <Col className="text-center" md={4}>
                Back
              </Col>{" "}
              <Col className="text-center" md={4}></Col>
              <Col className="text-center" md={4}>
                Lay
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="laegueRow position-relative">
        {runner?.map((item, index) => {
          const odds_back = backOdds?.filter((todd) =>
            typeGame == "bookmaker"
              ? todd?.runnerName == item?.RunnerName
              : todd.ri === item.SelectionId
          );
          const odds_lay = layOdds?.filter((todd) =>
            typeGame == "bookmaker"
              ? todd?.runnerName == item?.RunnerName
              : todd.ri === item.SelectionId
          );
          const old_back = oldBack?.filter((todd) =>
            typeGame == "bookmaker"
              ? todd?.runnerName == item?.RunnerName
              : todd.ri === item.SelectionId
          );
          const old_lay = oldLay?.filter((todd) =>
            typeGame == "bookmaker"
              ? todd?.runnerName == item?.RunnerName
              : todd.ri === item.SelectionId
          );
          let disabelBack = "lockBackBets" in item ? item?.lockBackBets : false;
          let disabelLay = "lockLayBets" in item ? item?.lockLayBets : false;

          const odds_lay2 =
            layOdds?.find((todd) =>
              typeGame == "bookmaker"
                ? todd?.runnerName == item?.RunnerName
                : todd.ri === item.SelectionId
            ) || {};
          let odds_back2 =
            backOdds?.find((todd) =>
              typeGame == "bookmaker"
                ? todd?.runnerName == item?.RunnerName
                : todd.ri === item.SelectionId
            ) || {};
          let layRt = odds_lay2?.rt > 0 ? odds_lay2?.rt : 0;
          let backRt = odds_back2?.rt > 0 ? odds_back2?.rt : 0;
          let disable = false;
          if (odds_lay?.length == 0 && odds_back?.length == 0) {
            disable = true;
          } else if (layRt <= 0 && backRt <= 0) {
            disable = true;
          } else {
            disable = false;
          }
          return (
            <div className="laegueRow position-relative">
              <Row className="position-relative">
                <Col md={7} className={""}>
                  {item?.RunnerName}
                  {(position?.length > 0 ||
                    Math.abs(item?.newPosition) > 0) && (
                    <span
                      className={`d-block ${
                        item?.position > 0
                          ? "text-green team-red-score"
                          : "team-red-score"
                      }`}
                    >
                      {position?.length > 0 && isNumber(item?.position)
                        ? item?.position > 0
                          ? Math?.abs(item?.position).toFixed(2)
                          : `( ${Math.abs(item?.position).toFixed(2)} )`
                        : ""}
                      {Math.abs(item?.newPosition) >= 0 && showBetDialog && betSlipObject?.playType == typeGame && (
                        <span
                          className={
                            item?.newPosition > 0
                              ? "text-green"
                              : "team-red-score"
                          }
                        >
                          {" "}
                          {">"}{" "}
                          {item?.newPosition > 0
                            ? Math.abs(item?.newPosition).toFixed(2)
                            : `( ${Math.abs(item?.newPosition).toFixed(2)} )`}
                        </span>
                      )}
                    </span>
                  )}
                </Col>
                <BackLay
                  component={typeGame}
                  index={index}
                  marketName={marketName}
                  item={item}
                  disabelBack={disabelBack}
                  oldBack={old_back}
                  oldLay={old_lay}
                  conditionNew={disable && ms == 1}
                  disabelLay={disabelLay}
                  handelChange={handelChange}
                  odds_lay={odds_lay}
                  odds_back={odds_back}
                  limit={localLimit}
                  ms={ms}
                />
              </Row>{" "}
            </div>
          );
        })}
        {ms != 1 && <div className="overlay-match-outer">Suspended</div>}
      </div>
    </div>
  );
};

export default Selection;
